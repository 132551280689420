import React, {useEffect} from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import {getFacility} from '@Features/facility/facilitySelectors';
import {getAffiliationHash} from '@Features/configuration/configurationSelectors';
import pubsub from '@Utils/pubsub';
import {EVENT_VIEW_ADDITIONS} from '@Utils/events';
import locale from '@Utils/locale';
import Header from '@Components/common/header/Header';
import Text from '@Components/common/text/Text';
import {useSelector} from 'react-redux';
import FlexBox from '@Components/common/flexBox/FlexBox';
import './additions.scss';
import {getAdditionById} from '@Utils/activity/activity';
import {getFormattedPrice} from '@Utils/format';
import classNames from 'classnames';
import ActivityAddition from './ActivityAdditions';
import {getBasketItemsAdditionsSelected} from './additionsUtils';
import {getBasketActivityItems, getIsNextStepBlocked} from '@Features/basket/basketSelectors';
import {ETextColor} from '@Components/common/text/types';
import {parseLanguage} from '@Utils/eventsUtils';
import {EFlexDirection} from '@Components/common/flexBox/types';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Button from '@mui/material/Button';

const Additions = ({
  activeView,
  onChangeView,
  fullCheckoutProgress,
  onPageClose,
  requestedCheckoutPageId
}) => {
  const facility = useSelector(getFacility);
  const affiliationHash = useSelector(getAffiliationHash);
  const isNextStepBlocked = useSelector(getIsNextStepBlocked);
  const {items, id: viewId} = activeView;
  const basketItem = items[0];
  const {activity, selections} = basketItem;
  const {numberOfParticipants} = selections;
  const {additions: activityAdditions, name: activityName, id: activityId} = {...activity};
  const activityBasketItems = useSelector(state => getBasketActivityItems(state, activityId));
  const activityBasketItemsSorted = activityBasketItems.sort((firstItem, secondItem) =>
    firstItem.variantId - secondItem.variantId);
  const additions = getBasketItemsAdditionsSelected(activityBasketItemsSorted) || [];
  const formValues = additions?.length &&
    additions.map(({additionId, quantity, numberOfSeats, selectedNumberOfSeats}) => (
      {additionId, quantity, numberOfSeats, selectedNumberOfSeats}
    ));
  const additionsCurrency = activityAdditions[0]?.price?.currency;

  useEffect(() => {
    if (requestedCheckoutPageId) {
      onChangeView(requestedCheckoutPageId);
    }
  }, [requestedCheckoutPageId]);

  useEffect(() => {
    pubsub.trigger(EVENT_VIEW_ADDITIONS, {
      numberOfAdditions: activityAdditions.length,
      facilityId: facility.id,
      facilityName: facility.name,
      industry: facility.company.industry,
      affiliationHash,
      language: parseLanguage(locale.locale)
    });
  }, []);

  function handleOnClosePage() {
    const isPageCloseRequest = true;

    return handleClickNext({isPageCloseRequest});
  }

  function handleClickNext({isPageCloseRequest}) {
    if (isPageCloseRequest) {
      return onPageClose();
    }

    return onChangeView(viewId + 1);
  }

  function getAdditionsCost() {
    return formValues.reduce((totalCost, {additionId, quantity, numberOfSeats, selectedNumberOfSeats}) => {
      const matchingAddition = getAdditionById(activityAdditions, additionId);

      if (matchingAddition) {
        const {price, isPricePerParticipant} = matchingAddition;
        let additionCost = price.amount * quantity;

        if (isPricePerParticipant) {
          additionCost *= selectedNumberOfSeats || 1;
          additionCost *= numberOfSeats || 1;
          additionCost *= numberOfParticipants || 1;
        }

        return parseFloat((totalCost + additionCost).toFixed(2));
      }

      return totalCost;
    }, 0);
  }

  const additionsCost = formValues?.length && getAdditionsCost();

  if (activityAdditions?.length) {
    return (
      <FlexBox direction={EFlexDirection.COLUMN} className="dl-dotpay dl-dotpay__checkout-page">
        <div className="dl-dotpay__content">
          <div className="dl-mb-3 dl-dotpay__content--with-icon">
            <Text color={ETextColor.LIGHT} wrap>
              {locale.translate('forChosen')} {activityName}
            </Text>
            <div
              className={classNames(
                {
                  'dl-dotpay--clickable dl-icon dl-icon--margin-correction dl-icon--arrow-up': fullCheckoutProgress
                })
              }
              onClick={handleOnClosePage}
            />
          </div>

          <div className="dl-mb-1">
            <Header>
              {locale.translate('chooseAdditions')}
            </Header>
          </div>

          <ul className="dl-mb-3 dl-additions" key={viewId}>
            {
              activityAdditions.map((addition, index) => (
                <ActivityAddition
                  activityName={activityName}
                  addition={addition}
                  basketItems={activityBasketItemsSorted}
                  additionViewId={index}
                  key={`variant-additions-${index + 1}`}
                />
              ))
            }
          </ul>

          <Button
            onClick={handleClickNext}
            variant="contained"
            color="success"
            fullWidth
            endIcon={<ChevronRightIcon />}
            disabled={isNextStepBlocked}
          >
            {locale.translate('next')} {additionsCost > 0 &&
                  `(+${getFormattedPrice(additionsCost)} ${locale.translate(`${additionsCurrency}`)})`
            }
          </Button>
        </div>
      </FlexBox>
    );
  }

  return null;
};

export default Additions;
