import React from 'react';
import ActivityVariantPicker from './ActivityVariantPicker';
import {
  getVariantDefaultCost,
  isVariantPriceCommon,
  isVariantPricePerDay
} from '@Utils/activity/variant';
import {useSelector} from 'react-redux';
import {getConfiguration} from '@Features/configuration/configurationSelectors';
import {getCatalogPrice} from '@Utils/variant';
import {getVariantPriceWithCurrency, isVariantCatalogPriceCommon} from '@Utils/activity/variantUtils';
import {IActivityProcessedVariantWithName} from '@Consts/globals';
import {sortByPriority} from './utils';
import Stack from '@mui/material/Stack';
import {CHECKOUT_PATH} from '@Utils/routerUtils';
import {useMatch} from 'react-router-dom';
import ActivityVariantName from './ActivityVariantName';
import CatalogPrice from './activityPrices/CatalogPrice';
import Price from './activityPrices/Price';
import PriceDescription from './activityPrices/PriceDescription';
import OfflinePrice from './activityPrices/OfflinePrice';
import {useFeatureFlag, Features} from '@Hooks/useFeatureFlag';

type Props = {
  activity: IActivityProcessedVariantWithName,
  orderNumber: number
}

const ActivityVariants = ({activity, orderNumber}: Props) => {
  const {passType, variants} = activity;
  const variantsByPriority = variants.sort(sortByPriority);
  const {widgetType} = useSelector(getConfiguration);
  const isUpsell = useMatch(CHECKOUT_PATH);
  const {isEnabled} = useFeatureFlag(Features.TEST_B);

  return (
    <>
      {
        variantsByPriority.map(variant => {
          const {id: variantId, type: variantType} = variant;
          const isPricePerDay = isVariantPricePerDay(variant);
          const cost = getVariantDefaultCost(variant);
          const {currency} = cost;
          const {priceText, abbr} = getVariantPriceWithCurrency({
            price: cost?.price, passType, isPricePerDay, currency, variantType
          });
          const {catalogPrice, catalogPriceDescription} = getCatalogPrice({cost, widgetType});
          const isVariantDifferentPrice = !isVariantPriceCommon(variant);
          const isVariantDifferentCatalogPrice = !isVariantCatalogPriceCommon(variant);

          return (
            <Stack
              key={variantId}
              direction={isUpsell ? 'column' : {xs: 'column', md: 'row'}}
              sx={() => wrapperStyles(!!isUpsell)}
            >
              <ActivityVariantName isUpsell={!!isUpsell} name={variant.name} description={variant.description} />
              <Stack
                sx={{
                  alignSelf: isUpsell ? 'end' : {xs: 'end', md: 'center'}
                }}
              >
                <Stack sx={{mb: 1, alignItems: 'end'}}>
                  <Stack direction="row" sx={{alignItems: 'baseline', justifyContent: 'end'}}>
                    {
                      catalogPrice && (
                        <CatalogPrice
                          catalogPrice={catalogPrice}
                          currency={currency}
                          withDifferentPrices={isVariantDifferentCatalogPrice}
                        />
                      )
                    }
                    <Price
                      withDifferentPrices={isVariantDifferentPrice}
                      priceText={priceText}
                      abbr={abbr}
                      isVariantPrice
                    />
                  </Stack>
                  <PriceDescription catalogPriceDescription={catalogPriceDescription} />
                  {isEnabled ? <OfflinePrice variant={variant} abbr={abbr} /> : null}
                </Stack>
                <ActivityVariantPicker
                  activity={activity}
                  variantId={variantId}
                  orderNumber={orderNumber}
                />
              </Stack>
            </Stack>
          );
        })
      }
    </>
  );
};

export default ActivityVariants;

const wrapperStyles = (isUpsell: boolean) => ({
  justifyContent: 'space-between',
  py: 1.5,
  px: isUpsell ? 1.5 : {xs: 1.5, md: 3},
  backgroundColor: '#f9f9fb',
  boxShadow: 'inset rgba(0, 0, 0, 0.1) 0 0 10px 1px',
  borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
  alignItems: 'center'
});
