import {
  SET_ORDER_STATUS,
  SET_SUBMIT_ORDER_STATUS,
  SET_SUBMIT_ORDER_ERROR
} from './orderActions';

const initialState = {
  status: null,
  data: null,
  submitOrderStatus: null,
  submitOrderError: null
};

const orderReducer = (state = initialState, action = {type: null}) => {
  switch (action.type) {
    case SET_ORDER_STATUS:
      return {
        ...state,
        status: action.payload.status
      };

    case SET_SUBMIT_ORDER_STATUS:
      return {
        ...state,
        submitOrderStatus: action.payload.submitOrderStatus
      };

    case SET_SUBMIT_ORDER_ERROR:
      return {
        ...state,
        submitOrderError: action.payload.submitOrderError
      };

    default:
      return state;
  }
};

export default orderReducer;
