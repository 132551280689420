import {IDatesGroupedByDay} from '@Components/checkoutPages/admissionRealization/types';
import {ECurrency, IPrice, TCost, TVariantCost} from '@Consts/apiGlobals';
import {EStatus} from '@Consts/status';
import {IBasketItem} from '@Consts/types';
import {ECheckoutViewsTypes} from '@Utils/checkoutViews/checkoutViewsConsts';
import dayjs from 'dayjs';
import {IConfigurationStore} from 'src/js/store/types';

export type VariantType = 1 | 2;

export interface IFetchAdmissionDatesAndFilterAvailableProps {
  url: string,
  configuration: IConfigurationStore
}

export interface IResource {
  resourceId: number,
  amount: number
}

export interface IAdmissionDateVariant {
  variantId: number;
  resource: IResource | null;
  cost: TVariantCost;
  variantType: VariantType;
  offlinePrice: IPrice | null
}

export type ApiAdmissionDateVariant = {
  variantId: number;
  variantType: VariantType;
  costMin: TCost;
  costMax: TCost;
}

export type ApiDayOffers = {
  date: string;
  isAvailable: boolean;
  variants: ApiAdmissionDateVariant[];
}

export type DayOffersProcessed = {
  id: number;
  date: dayjs.Dayjs;
  originalDate: string;
  isAvailable: boolean;
  variants: ApiAdmissionDateVariant[];
  isCommonVariantsPrice: boolean;
  currency: ECurrency;
  totalPrice: number;
  isBargainPrice?: boolean;
  isSelected: boolean;
}

export interface IAdmissionDateProcessed {
  id: number | null,
  date: string | null,
  originalDate: string,
  description: string | null,
  numberOfSeats: number,
  resourceExists: boolean,
  variants: IAdmissionDateVariant[]
}

export interface ICalendarMonthSelection {
  month: number | null,
  year: number | null
}

export interface IFetchAdmissionDatesProps {
  activityId: number,
  calendarMonth: number,
  calendarDate?: number,
  calendarYear: number,
  numberOfAdmissionDatesMax: number,
  activityVariantsIds: number[],
  activeViewItems: IBasketItem[],
  itemsVariants: number[],
  selectedDates: IDatesGroupedByDay[] | null,
  canShowItemsLeft: boolean,
  activeViewType: ECheckoutViewsTypes
}

export type FetchDayOffersProps = {
  activityId: number;
  calendarMonth: number;
  calendarYear: number;
  numberOfAdmissionDatesMax: number;
  activityVariantsIds: number[];
}

export enum EAdmissionDatesActionsTypes {
  SET_ACTIVITY_ADMISSION_DATES = 'SET_ACTIVITY_ADMISSION_DATES',
  SET_ACTIVITY_ADMISSION_DATES_STATUS = 'SET_ACTIVITY_ADMISSION_DATES_STATUS',
  CLEAR_ACTIVITY_ADMISSION_DATES = 'CLEAR_ACTIVITY_ADMISSION_DATES',
  SET_DAY_OFFER = 'SET_DAY_OFFER'
}

export type TAdmissionDatesActions = {
  type: EAdmissionDatesActionsTypes.SET_ACTIVITY_ADMISSION_DATES,
  payload: {dates: AdmissionDateExtended[]}
} | {
  type: EAdmissionDatesActionsTypes.SET_ACTIVITY_ADMISSION_DATES_STATUS,
  payload: {datesStatus: EStatus | null}
} | {
  type: EAdmissionDatesActionsTypes.SET_DAY_OFFER,
  payload: {dayOffers: DayOffersProcessed[]}
}

export interface IActivityAdmissionDatesApiParams {
  locale: string,
  activityVariantsIds: number[],
  limit?: number | null,
  sinceDate: string,
  uniltDate: string,
  affiliationHash: string | null
}

export interface AdmissionDateExtended {
  date: string | null;
  numberOfSeats: number;
  resourceExists: boolean;
  description: string | null;
  variants: IAdmissionDateVariant[];
  id: number | null,
  originalDate: string;
  takenSeats: number;
  totalPrice: number;
  totalOfflinePrice: number | null;
  currency: ECurrency;
  isCommonPrice: boolean;
  isOutOfLimit: boolean;
  variantsCostsAreValid: boolean;
  catalogPrice: number | null,
  catalogPriceDescription: string | null
  isPricePerAdmissionDate: boolean;
}

export interface AdmissionDate {
  date: string;
  numberOfSeats: number;
  resourceExists: boolean;
  description: string | null;
  variants: IAdmissionDateVariant[];
  takenSeats: number;
  totalPrice: number;
  currency: ECurrency;
  isCommonPrice: boolean;
  isOutOfLimit: boolean;
  variantsCostsAreValid: boolean;
}
