import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import {shouldShowVariantsPickers} from '@Utils/activity/activity';
import ActivityItemWithVariants from './ActivityItemWithVariants';
import ActivityItemWithoutVariants from './ActivityItemWithoutVariants';
import {IActivityProcessed, IActivityProcessedVariantWithName} from '@Consts/globals';

type Props = {
  activity: IActivityProcessed | IActivityProcessedVariantWithName,
  indicated?: boolean,
  orderNumber: number,
  numberOfActivities: number
}

const ActivityItem = ({activity, indicated, orderNumber, numberOfActivities}: Props) => {
  const showVariantPickers = shouldShowVariantsPickers(activity.variants);

  if (showVariantPickers) {
    return (
      <ActivityItemWithVariants
        activity={activity as IActivityProcessedVariantWithName}
        indicated={indicated}
        orderNumber={orderNumber}
        numberOfActivities={numberOfActivities}
      />
    );
  }

  return (
    <ActivityItemWithoutVariants
      activity={activity as IActivityProcessed}
      indicated={indicated}
      orderNumber={orderNumber}
      numberOfActivities={numberOfActivities}
    />
  );
};

export default ActivityItem;
