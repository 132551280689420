import {handleChangeCheckoutViews} from '@Components/checkout/checkoutUtils';
import {resetBasketDiscount, updateBasketItemsSelections} from '@Features/basket/basketActions';
import {resetDiscount} from '@Features/discount/discountActions';
import {getDiscount} from '@Features/discount/discountSelectors';
import {getCheckoutView} from '@Utils/checkout';
import {getCheckoutViews} from '@Utils/checkoutViews/checkoutViews';
import {IBasketItem} from '@Consts/types';
import {MutableRefObject, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getShippingMethods} from '@Features/shippingMethods/shippingMethodsSelectors';
import {getUpsellActivities} from '@Features/upsell/upsellSelectors';
import {useScroll} from './useScroll';

export const useHandleChangeCheckoutViews = ({basketItems, submitCheckoutRef}: {
    basketItems: IBasketItem[],
    submitCheckoutRef: MutableRefObject<HTMLButtonElement> | null
}) => {
  const dispatch = useDispatch();
  const discount = useSelector(getDiscount);
  const numberOfShippingMethods = useSelector(getShippingMethods)?.length || 0;
  const upsellActivities = useSelector(getUpsellActivities);
  const checkoutViews = getCheckoutViews(basketItems, numberOfShippingMethods, upsellActivities);
  const [checkoutViewsState, setCheckoutViewsState] = useState(checkoutViews);
  const [activeView, setActiveView] = useState(checkoutViews?.[0] || null);
  const [fullCheckoutProgress, setFullCheckoutProgress] = useState(activeView ? false : true);
  const [requestedCheckoutPageId, setRequestedCheckoutPageId] = useState<number | null>(null);
  const [isUpsellUpdated, setIsUpsellUpdated] = useState(false);
  const {scrollToElement} = useScroll();

  function scrollEffect() {
    if (submitCheckoutRef?.current) {
      scrollToElement(submitCheckoutRef.current);
    }
  }

  function handleSetUpsellUpdated(isUpdate: boolean) {
    setIsUpsellUpdated(isUpdate);
  }

  useEffect(() => {
    const upsellViewResetDate = (currentItems: any) => {
      dispatch(updateBasketItemsSelections(
        currentItems, {dates: null, time: null}
      ));
      setIsUpsellUpdated(true);
      setFullCheckoutProgress(false);
    };

    handleChangeCheckoutViews(
      checkoutViewsState,
      checkoutViews,
      activeView,
      setActiveView,
      setFullCheckoutProgress,
      upsellViewResetDate
    );
    if (activeView?.id) {
      setActiveView(getCheckoutView(checkoutViews, activeView?.id));
    }
    setCheckoutViewsState(checkoutViews);
  }, [basketItems]);

  useEffect(() => {
    if (!activeView && checkoutViewsState) {
      setTimeout(scrollEffect, 550);
    }
  }, [activeView]);

  function handleClickHeader(viewId: number) {
    if (activeView) {
      setRequestedCheckoutPageId(viewId);
    } else {
      setActiveView(getCheckoutView(checkoutViews, viewId));
    }

    if (fullCheckoutProgress && discount) {
      dispatch(resetBasketDiscount());
      dispatch(resetDiscount());
    }
  }

  function handleChangeView(changeToViewId: number) {
    const requestedCheckoutView = getCheckoutView(checkoutViews, changeToViewId);

    setRequestedCheckoutPageId(null);

    if (requestedCheckoutView) {
      setActiveView(requestedCheckoutView);
    } else {
      setFullCheckoutProgress(true);
      setActiveView(null);
    }
  }

  function handleCloseActivePage() {
    setActiveView(null);
  }

  return {
    checkoutViews: checkoutViewsState,
    activeView,
    fullCheckoutProgress,
    requestedCheckoutPageId,
    isUpsellUpdated,
    handleChangeView,
    handleCloseActivePage,
    handleClickHeader,
    handleSetUpsellUpdated
  };
};
