import React, {useEffect, useMemo} from 'react';
import {trackEvent} from '@Utils/sentry';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import locale from '@Utils/locale';
import {isRouteErrorResponse, useRouteError} from 'react-router-dom';
import RefreshOutlined from '@mui/icons-material/RefreshOutlined';
import {useDispatch, useSelector} from 'react-redux';
import {clearBasket} from '@Features/basket/basketActions';
import {clearBasketItemsInStorage} from '@Features/basket/basketUtils';
import {getStorageBasketItems} from '@Features/storage/storageSelectors';
import {getOnlineGroupId} from '@Features/configuration/configurationSelectors';

const ErrorBoundary = () => {
  const error = useRouteError();
  const dispatch = useDispatch();
  const onlineGroupId = useSelector(getOnlineGroupId);
  const storageBasketItems = useSelector(getStorageBasketItems) || [];

  const message = useMemo(() => {
    if (isRouteErrorResponse(error)) {
      return error.data;
    }

    if (error instanceof Error) {
      return error.message;
    }

    return locale.translate('unknownError');
  }, [error]);

  useEffect(() => {
    if (message) {
      trackEvent(message, {error});
    }
  }, [message, error]);

  const handleClearBasket = () => {
    dispatch(clearBasket());
    dispatch(clearBasketItemsInStorage(onlineGroupId, storageBasketItems));
  };

  const handleRefresh = () => {
    handleClearBasket();
    window.location.reload();
  };

  return (
    <Stack alignItems="center" gap={2} py={24} px={4}>
      <Stack component="img" alt="person" src="img/page_error.svg" />
      <Typography variant="h6" component="h3" align="center">
        {locale.translate('cannotLoadPage')}
      </Typography>
      <Typography variant="body1" align="center" color="textSecondary" mb={4} maxWidth={620}>
        <p>{message}</p>
        <span>{new Date().toISOString()}</span>
      </Typography>
      <Button
        variant="contained"
        color="success"
        endIcon={<RefreshOutlined />}
        onClick={handleRefresh}
      >
        {locale.translate('refreshPage')}
      </Button>
    </Stack>
  );
};

export default ErrorBoundary;
