import Box from '@mui/material/Box';
import React from 'react';

type Props = {
    checkoutViewId: number,
    activeViewId?: number
}

const DisabledCheckoutHeaderWrapper = ({children, checkoutViewId, activeViewId}: React.PropsWithChildren<Props>) => {
  if (!activeViewId) {
    return children;
  }

  if (activeViewId < checkoutViewId) {
    return (
      <Box sx={{opacity: '50%', pointerEvents: 'none'}}>
        {children}
      </Box>
    );
  }

  return children;
};

export default DisabledCheckoutHeaderWrapper;
