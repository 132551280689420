import Typography from '@mui/material/Typography';
import React from 'react';

type Props = {
    variantsDescription: string
}

const ActivityVariantsDescription = ({variantsDescription}: Props) => (
  <Typography
    variant="h5"
    sx={{
      fontSize: '18px',
      fontWeight: 700,
      textAlign: 'right',
      wordBreak: 'break-word'
    }}
  >
    {variantsDescription}
  </Typography>
);

export default ActivityVariantsDescription;
