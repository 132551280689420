/* eslint-disable max-len */
import {IFacility} from '@Consts/apiGlobals';
import {IBasketItem} from '@Consts/types';
import directFrameMessage from '@Utils/directFrameMessage';
import {GTM_MESSAGE_NAME, EGtmEvents} from './core';
import {clearEcommerce, getBasketTotalPrice, getSelectionFormatAdmissionDate, getSelectionsVariantPrice} from './utils';

type ViewItemsData = {
  [variantId: number]: {
    item_id: number;
    item_name: string;
    item_list_id: number | null;
    item_variant: string | null;
    quantity: number;
  };
};

type ViewItemsCompletedData = {
  [variantId: number]: {
    item_id: number;
    item_name: string;
    item_list_id: number | null;
    item_variant: string | null;
    quantity: number;
    price: number,
    admission_date: string | null
  };
};

const getViewItemsData = (
  basketItemData: IBasketItem[],
  onlineGroupId: number | null
) => Object.values(
  basketItemData.reduce<ViewItemsData>((acc, item) => {
    const {variantId} = item;

    acc[variantId] = {
      item_id: item.activityId,
      item_name: item.activity.name,
      item_list_id: onlineGroupId,
      item_variant: item.variant.name,
      quantity:
            (acc[variantId]?.quantity ?? 0) +
            (item.selections?.numberOfParticipants ??
            item.selections?.numberOfSeats ??
            item.variant?.numberOfSeats ??
            0)
    };

    return acc;
  }, {})
);

const viewAdmissionDates = (facility: IFacility, basketItemData: IBasketItem[], onlineGroupId: number | null) => {
  const {isGoogleTagManagerEnabled} = facility;

  if (!isGoogleTagManagerEnabled) {
    return;
  }

  clearEcommerce();
  directFrameMessage({
    name: GTM_MESSAGE_NAME,
    gtmData: {
      event: EGtmEvents.GTM_TAG_VIEW_ADMISSION_DATES,
      ecommerce: {
        items: getViewItemsData(basketItemData, onlineGroupId)
      }
    }
  });
};

const getViewItemsCompletedData = (
  basketItemData: IBasketItem[],
  onlineGroupId: number | null,
  timezone: string
) => Object.values(
  basketItemData.reduce<ViewItemsCompletedData>((acc, item) => {
    const {variantId, selections} = item;

    acc[variantId] = {
      item_id: item.activityId,
      item_name: item.activity.name,
      item_list_id: onlineGroupId,
      item_variant: item.variant.name,
      quantity:
            (acc[variantId]?.quantity ?? 0) +
            (selections?.numberOfParticipants ?? selections?.numberOfSeats ?? 1),
      price: getSelectionsVariantPrice(variantId, selections)!,
      admission_date: getSelectionFormatAdmissionDate(selections, timezone)!
    };

    return acc;
  }, {})
);

const viewAdmissionsCompleted = (facility: IFacility, basketItemData: IBasketItem[], onlineGroupId: number | null) => {
  const {isGoogleTagManagerEnabled, timezone} = facility;

  if (!isGoogleTagManagerEnabled) {
    return;
  }

  const currency = basketItemData[0].selections.time?.currency! || basketItemData[0].selections.dates?.[0].metaData.currency!;

  clearEcommerce();
  directFrameMessage({
    name: GTM_MESSAGE_NAME,
    gtmData: {
      event: EGtmEvents.GTM_TAG_VIEW_ADMISSION_COMPLETED,
      ecommerce: {
        currency,
        value: getBasketTotalPrice(basketItemData[0].selections)!,
        items: getViewItemsCompletedData(basketItemData, onlineGroupId, timezone)
      }
    }
  });
};

export default {
  viewAdmissionDates,
  viewAdmissionsCompleted
};
