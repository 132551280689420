import {useEffect, useState} from 'react';
import {AdmissionDateExtended, DayOffersProcessed} from '@Features/activityAdmissionDates/types';

export type Props = {
  isCommonPrice: boolean,
  datesExtended: AdmissionDateExtended[] | null,
  dayOffers: DayOffersProcessed[] | null
};

export const useDetectChangedPrices = ({
  isCommonPrice,
  datesExtended,
  dayOffers
}: Props) => {
  const [isPriceChanged, setIsPriceChanged] = useState(false);
  const [referencePrice, setReferencePrice] = useState<number | null>(null);

  type Dates = (DayOffersProcessed | AdmissionDateExtended)[];

  const isPriceChangedPerMonth = (dates: Dates) => dates.some(
    date => date.totalPrice !== referencePrice
  );

  useEffect(() => {
    // Establish the reference price. It is constant value for every months.
    const datesAreReady = datesExtended || dayOffers;

    if (datesAreReady && !referencePrice) {
      setReferencePrice(
        dayOffers?.[0]?.totalPrice ??
        datesExtended?.[0]?.totalPrice ??
        null
      );
    }
  }, [datesExtended, dayOffers]);

  useEffect(() => {
    if (isCommonPrice && referencePrice && !isPriceChanged) {
      if (dayOffers?.length) {
        setIsPriceChanged(isPriceChangedPerMonth(dayOffers));
      } else if (datesExtended?.length) {
        setIsPriceChanged(isPriceChangedPerMonth(datesExtended));
      }
    }
  }, [datesExtended, dayOffers]);

  return {isPriceChanged};
};

export default useDetectChangedPrices;
