import {OnlinePaymentMethods} from '@Components/checkout/types';
import FormRadioGroup from '@Components/formComponents/FormRadioGroup';
import React from 'react';
import {string} from 'yup';
import {useUnregisterFormField} from '@Hooks/useHideFormField';
import {usePaymentMethodPickerInputs} from './usePaymentMethodPickerInputs';

export const GROUP_FIELD_NAME = 'paymentMethod';

export const schema = () => string()
  .oneOf(Object.values(OnlinePaymentMethods))
  .required();

type Props = {
  groupName?: string,
}

const PaymentMethodPicker = ({groupName = GROUP_FIELD_NAME}: Props) => {
  useUnregisterFormField(groupName);

  const radioInputs = usePaymentMethodPickerInputs();

  return (
    <FormRadioGroup
      groupName={groupName}
      radioInputs={radioInputs}
      spacing={2}
    />
  );
};

export default PaymentMethodPicker;
