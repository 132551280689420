import React from 'react';
import {string} from 'yup';
import {InternalPaymentMethods} from '../types';
import FormRadioGroup from '@Components/formComponents/FormRadioGroup';
import locale from '@Utils/locale';
import {useSelector} from 'react-redux';
import {getFacility} from '@Features/facility/facilitySelectors';

export const GROUP_FIELD_NAME = 'paymentMethod';
export const schema = () => string().oneOf([
  InternalPaymentMethods.OTHER,
  InternalPaymentMethods.PAYMENT_CARD,
  InternalPaymentMethods.CASH,
  InternalPaymentMethods.BANK_TRANSFER
]).required();

  interface IPaymentMethodField {
    groupName?: string,
}

const PaymentMethodField = ({groupName = GROUP_FIELD_NAME}: IPaymentMethodField) => {
  const facility = useSelector(getFacility);
  const {company} = {...facility};
  const {isInternalPaymentReceiptEnabled} = {...company};
  const formTypesRadioInputsWithReceipt = [
    {value: InternalPaymentMethods.PAYMENT_CARD, label: locale.translate('paymentTerminal')},
    {value: InternalPaymentMethods.CASH, label: locale.translate('cash')},
    {value: InternalPaymentMethods.BANK_TRANSFER, label: locale.translate('bankTransfer')}
  ];

  const formTypesRadioInputs = [
    ...formTypesRadioInputsWithReceipt,
    {value: InternalPaymentMethods.OTHER, label: locale.translate('other')}
  ];

  return (
    <FormRadioGroup
      groupName={groupName}
      radioInputs={isInternalPaymentReceiptEnabled ? formTypesRadioInputsWithReceipt : formTypesRadioInputs}
    />
  );
};

export default PaymentMethodField;
