type StorageEventData = {
  key: string;
  data: Record<string, unknown> | Record<string, unknown>[] | string | number | boolean;
  namespace?: string;
}

type StorageNamespaceInput = {
  facilityId: number;
  groupId: number | null;
  onlineGroupId: number | null;
  businessGroupId: number | null;
  companyUserId?: number | null;
  internalBuyerFacilityId?: number | null;
  internal?: boolean | null;
  previewOnlineGroupId?: number | null;
}

export const STORAGE_PREFIX = 'dlStorage-';

export const getNamespace = ({
  facilityId,
  groupId = null,
  onlineGroupId = null,
  businessGroupId = null,
  companyUserId = null,
  internalBuyerFacilityId = null,
  internal = null,
  previewOnlineGroupId = null
}: StorageNamespaceInput) => {
  let uniqueId = '';
  const isClientPanelStorage = companyUserId || businessGroupId || internalBuyerFacilityId || previewOnlineGroupId;

  if (isClientPanelStorage) {
    // eslint-disable-next-line max-len
    uniqueId = `${facilityId}/${companyUserId}/${!!internal}/${previewOnlineGroupId}/${businessGroupId}/${internalBuyerFacilityId}`;
  } else {
    // businessGroupId is only for client panel purpose but it's currently create storage namespace
    // for online users. So now it could stay to prevent reset users storages.
    uniqueId = `${facilityId}/${groupId}/${onlineGroupId}/${businessGroupId}`;
  }

  const encodedUniqueId = window.btoa(uniqueId); // btoa() alone is deprecated in Node.js

  return `${STORAGE_PREFIX}${encodedUniqueId}`;
};

/**
 * Sets a namespaced storage item in localStorage.
 * @param {StorageEventData} eventData - An object containing the key, data, and namespace for the storage item.
 * @returns {void}
 */
export const setNamespacedStorage = (eventData: StorageEventData) => {
  const {key, data, namespace} = eventData;
  const storageValue = JSON.stringify(data);

  if (namespace) {
    const storage = localStorage.getItem(namespace);

    try {
      const parsedStorage = JSON.parse(storage!);

      if (storage) {
        localStorage.setItem(namespace, JSON.stringify({...parsedStorage, [key]: data}));
      } else {
        localStorage.setItem(namespace, JSON.stringify({[key]: data}));
      }
    } catch (error) {
      localStorage.setItem(namespace, JSON.stringify({[key]: data}));
    }

    return;
  }

  localStorage.setItem(key, storageValue);
};

/**
 * Removes a namespaced storage item from localStorage.
 * @param {Omit<StorageEventData, 'data'>} eventData - An object containing the key and namespace for the storage item.
 * @returns {void}
 */
export const removeNamespacedStorage = (eventData: Omit<StorageEventData, 'data'>) => {
  const {key, namespace} = eventData;

  if (namespace) {
    const storage = localStorage.getItem(namespace);

    try {
      const parsedStorage = JSON.parse(storage!);

      if (storage) {
        delete parsedStorage[key];
        localStorage.setItem(namespace, JSON.stringify(parsedStorage));
      }
    } catch (error) {
      // no valid data in storage, ignore
    }
  }

  localStorage.removeItem(key);
};

/**
 * Gets all namespaced storage items from localStorage.
 * @returns {Record<string, unknown>} - An object containing all namespaced storage items.
 */
export const getNamespacedStorage = () => {
  const storage: Record<string, unknown> = {};

  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);

    if (key?.startsWith(STORAGE_PREFIX)) {
      const value = localStorage.getItem(key);

      if (value) {
        storage[key] = JSON.parse(value);
      }
    }
  }

  return storage;
};
