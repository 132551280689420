import React, {useState} from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import CheckoutPagesListItem from './CheckoutPagesListItem';
import {ECheckoutViewsTypes} from '@Utils/checkoutViews/checkoutViewsConsts';

const CheckoutPagesList = ({
  activeView,
  checkoutViews,
  onHeaderClick,
  onChangeView,
  fullCheckoutProgress,
  onPageClose,
  requestedCheckoutPageId,
  isUpsellUpdated,
  handleSetUpsellUpdated
}) => {
  const [checkoutProgressId, setCheckoutProgressId] = useState(checkoutViews?.[0]?.id || null);

  React.useEffect(() => {
    if (checkoutProgressId < activeView?.id) {
      setCheckoutProgressId(activeView?.id);
    }
  }, [activeView]);

  React.useEffect(() => {
    if (checkoutProgressId > checkoutViews?.length) {
      setCheckoutProgressId(checkoutViews[checkoutViews?.length - 1]?.id);
    }
  }, [checkoutViews]);

  React.useEffect(() => {
    if (isUpsellUpdated && checkoutViews) {
      const upsellIndex = checkoutViews.findIndex(view => view.type === ECheckoutViewsTypes.UPSELL);

      setCheckoutProgressId(upsellIndex + 1);
      handleSetUpsellUpdated(false);
    }
  }, [isUpsellUpdated]);

  return (
    <div className={'checkout-pages-list'} >
      {
        checkoutViews && checkoutViews.map(checkoutView => {
          if (checkoutView.id <= checkoutProgressId) {
            return (
              <CheckoutPagesListItem
                checkoutView={checkoutView}
                onHeaderClick={onHeaderClick}
                key={checkoutView.id}
                activeView={activeView}
                onChangeView={onChangeView}
                fullCheckoutProgress={fullCheckoutProgress}
                onPageClose={onPageClose}
                requestedCheckoutPageId={requestedCheckoutPageId}
              />
            );
          }
          return null;
        })
      }
    </div>
  );
};

export default CheckoutPagesList;
