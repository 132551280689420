import React, {useRef} from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import {getFacility} from '@Features/facility/facilitySelectors';
import BusinessSaleAgreement from '@Components/termsOfService/BusinessSaleAgreement';
import {getAffiliationHash, getWidgetType} from '@Features/configuration/configurationSelectors';
import {
  isWidgetBusiness,
  isWidgetBusinessPreview,
  isWidgetOnlineClient,
  isWidgetOnlineClientPreview
} from '@Utils/widgetType';
import AgreementsForm from './AgreementsForm';
import {useSelector} from 'react-redux';
import Text from '@Components/common/text/Text';
import {ETextColor, ETextModifier, ETextTransform} from '@Components/common/text/types';
import locale from '@Utils/locale';
import {HOME_PATH} from '@Utils/routerUtils';
import './agreements.scss';
import pubsub from '@Utils/pubsub';
import {EVENT_BUY_SHOW_ERROR_DEPENDENT_VARIANT} from '@Utils/events';
import {useNavigate} from 'react-router-dom';

const Agreements = ({onSubmit, missingActivities, chosenVariants}) => {
  const navigate = useNavigate();
  const facility = useSelector(getFacility);
  const widgetType = useSelector(getWidgetType);
  const affiliationHash = useSelector(getAffiliationHash);
  const widgetIsOnlineOrPreview = isWidgetOnlineClient(widgetType) || isWidgetOnlineClientPreview(widgetType);
  const widgetIsBusinessOrPreview = isWidgetBusiness(widgetType) || isWidgetBusinessPreview(widgetType);
  const {company, id: facilityId, name: facilityName} = facility;
  const {paymentType} = company;
  const showMissingVariantsError =
    (widgetIsOnlineOrPreview || widgetIsBusinessOrPreview) &&
    missingActivities?.length > 0;
  const wrapperRef = useRef();

  if (showMissingVariantsError) {
    const missingVariantsIds = missingActivities.flatMap(({variants}) => variants.map(({id}) => id));

    pubsub.trigger(EVENT_BUY_SHOW_ERROR_DEPENDENT_VARIANT, {
      facilityId,
      facilityName,
      chosenVariants,
      affiliationHash,
      missingVariants: missingVariantsIds
    });
  }

  const handleClick = () => {
    navigate(HOME_PATH);
  };

  return (
    <div className="dl-dotpay dl-dotpay__checkout-submit-form" ref={wrapperRef}>
      {
        showMissingVariantsError && (
          <div className="dl-mb-4">
            <Text color={ETextColor.ERROR}>
              {`${locale.translate('requiredActivityVariants')} ${locale.translate('backTo')} `}
            </Text>

            <Text
              transform={ETextTransform.UNDERLINE}
              modifier={ETextModifier.CLICKABLE}
              onClick={handleClick}
              color={ETextColor.ERROR}
              dl-test={'text-clickable'}
            >
              {locale.translate('choosing')}
            </Text>

            <Text color={ETextColor.ERROR}>
              {` ${locale.translate('and')} ${locale.translate('addSmall')}
              ${missingActivities.length > 1 ?
                locale.translate('oneOfRequiredActivity') :
                missingActivities[0].variants.length > 1 ?
                  locale.translate('oneOfRequiredActivityVariants') : locale.translate('requiredActivity')
              }:`}
            </Text>
            <div className={'dl-w-100'}>
              <ol className={'dl-unordered-list__normal'}>
                {missingActivities.map(({activityId, activityName, variants}) => (
                  <li key={activityId} className={'item--error'} dl-test="list-item">
                    {`${activityName}${variants[0].name ? ':' : ''}`}
                    <ul className={'dl-unordered-list__normal'}>
                      {variants.map(({id, name}) => name && <li key={id}>{name}</li>)}
                    </ul>
                  </li>
                ))}
              </ol>
            </div>
          </div>
        )
      }

      <AgreementsForm
        onSubmit={onSubmit}
        wrapperRef={wrapperRef}
        submitDisabled={missingActivities?.length > 0}
      />

      {
        widgetIsBusinessOrPreview && (
          <div className="dl-mb-2" >
            <BusinessSaleAgreement paymentType={paymentType} company={company} />
          </div>
        )
      }

    </div>
  );
};

export default Agreements;
