import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {grey} from '@mui/material/colors';
import {OverridableComponent} from '@mui/material/OverridableComponent';
import React from 'react';
import {SvgIconTypeMap} from '@mui/material';

type Props = {
    Icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
        muiName: string;
    },
    boldText?: string,
    text?: string | number;
    backgroundColor?: string
}

const AdmissionSummaryDetails = (
  {children, Icon, boldText, text, backgroundColor = grey[50]} : React.PropsWithChildren<Props>) => {
  if (!children && !!text && !!boldText) {
    return (
      <Stack
        spacing={1.5}
        direction="row"
        sx={{
          backgroundColor,
          px: 2,
          py: '6px'
        }}
      >
        <Box sx={{py: '7px'}}>
          <Icon color="success" sx={{fontSize: '22px'}} />
        </Box>
        <Typography
          variant="body2"
          sx={{
            alignContent: 'center',
            py: 1,
            fontSize: '14px'
          }}
        >
          {text}
          <Typography
            variant="body2"
            component="span"
            sx={{
              fontWeight: 700,
              fontSize: '14px'
            }}
          >
            {boldText}
          </Typography>
        </Typography>
      </Stack>
    );
  }

  return (
    <Stack
      direction="row"
      spacing={1.5}
      sx={{
        backgroundColor,
        px: 2,
        py: '6px'
      }}
    >
      <Box sx={{py: '7px'}}>
        <Icon color="success" sx={{fontSize: '22px'}} />
      </Box>
      {children}
    </Stack>
  );
};

export default AdmissionSummaryDetails;
