import {IBasketItem} from '@Consts/types';
import {getBasketItems} from '@Features/basket/basketSelectors';
import {getFacility} from '@Features/facility/facilitySelectors';
import {getBasketValue} from '@Utils/basket';
import {useSelector} from 'react-redux';
import {getWidgetType} from '@Features/configuration/configurationSelectors';
import {isWidgetOnlineClient, isWidgetOnlineClientPreview} from '@Utils/widgetType';
import {isIOSDeviceOrMacSafari} from '@Utils/utils';
import {getIsApplePaySupported} from '@Features/storage/storageSelectors';

export type EnabledPaymentMethods = {
  isSecureFormBlikEnabled: boolean;
  isCardEnabled: boolean;
  isApplePayEnabled: boolean;
};

export const usePaymentMethodsEnabled = ({checkBasketPrice = true} = {}): EnabledPaymentMethods => {
  const isApplePaySupported = useSelector(getIsApplePaySupported);
  const facility = useSelector(getFacility);

  if (!facility) {
    return {
      isSecureFormBlikEnabled: false,
      isCardEnabled: false,
      isApplePayEnabled: false
    };
  }

  const {isPaymentProviderBlikEnabled, isPaymentProviderCardsEnabled} = facility.company;
  const basketItems: IBasketItem[] = useSelector(getBasketItems);
  const widgetType = useSelector(getWidgetType);
  const isWidgetOnlineType = isWidgetOnlineClient(widgetType) || isWidgetOnlineClientPreview(widgetType);
  const basketPriceValue = getBasketValue(basketItems, true);
  const isWidgetOnlineToPay = isWidgetOnlineType && (checkBasketPrice ? basketPriceValue > 0 : true);
  const isAppleDevice = isIOSDeviceOrMacSafari();

  const isSecureFormBlikEnabled = isPaymentProviderBlikEnabled && isWidgetOnlineToPay;
  const isCardEnabled = isPaymentProviderCardsEnabled && isWidgetOnlineToPay;
  const isApplePayEnabled = isCardEnabled && isAppleDevice && isApplePaySupported;

  return {
    isSecureFormBlikEnabled,
    isCardEnabled,
    isApplePayEnabled
  };
};
