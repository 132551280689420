import Typography from '@mui/material/Typography';
import React from 'react';

type Props = {
  catalogPriceDescription: string | null
}

const PriceDescription = ({catalogPriceDescription}: Props) => {
  if (!catalogPriceDescription) {
    return null;
  }

  return (
    <Typography
      component="p"
      variant="caption"
      sx={{
        color: 'text.secondary',
        textAlign: 'right',
        fontSize: '12px',
        maxWidth: '200px'
      }}
    >
      {catalogPriceDescription}
    </Typography>
  );
};

export default PriceDescription;
