import React from 'react';
import locale from '@Utils/locale';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';

type WalletPassProps = {
  onClick?: () => void,
  url?: string | null,
  testid: string,
  srcImg: string,
  target?: '_blank' | '_self' | '_parent' | '_top'
}

const WalletPass = ({url, onClick, testid, srcImg, target = '_blank'}: WalletPassProps) => (
  <Link
    component={url ? Link : 'span'}
    data-testid={testid}
    href={url ? url : undefined}
    onClick={onClick}
    target={target}
  >
    <Box
      sx={{height: 35, opacity: url ? 1 : 0.5}}
      component="img"
      src={srcImg}
      alt={`language ${locale.locale}`}
    />
  </Link>
);

export default WalletPass;
