import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import {getCheckoutPageListItemContent} from '@Utils/checkout';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import DisabledCheckoutHeaderWrapper from './DisabledCheckoutHeaderWrapper';

const CheckoutHeader = ({checkoutView, onClick, activeView}) => {
  function handleClick() {
    return onClick(checkoutView.id);
  }

  return (
    <DisabledCheckoutHeaderWrapper checkoutViewId={checkoutView.id} activeViewId={activeView?.id}>
      <div
        className="dl-dotpay dl-dotpay--alternative-layout dl-dotpay--clickable"
        onClick={handleClick}
      >
        <div className="dl-extendable__content">
          <div
            className="dl-header js-header-text dl-extendable__content-text"
          >
            {getCheckoutPageListItemContent(checkoutView)}
          </div>
        </div>

        <div className="dl-extendable__more">
          <KeyboardArrowDownOutlinedIcon />
        </div>
      </div>
    </DisabledCheckoutHeaderWrapper>
  );
};

export default CheckoutHeader;
