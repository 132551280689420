import pubsub from '@Utils/pubsub';
import {EVENT_ADD_TO_BASKET, EVENT_ADD_TO_BASKET_UPSELL} from '@Utils/events';
import {parseLanguage} from '@Utils/eventsUtils';
import locale from '@Utils/locale';
import {useMatch} from 'react-router-dom';
import {CHECKOUT_PATH} from '@Utils/routerUtils';
import {useSelector} from 'react-redux';
import {getAffiliationHash} from '@Features/configuration/configurationSelectors';
import {getFacility} from '@Features/facility/facilitySelectors';
import {IActivityProcessed, IActivityVariantProcessed} from '@Consts/globals';
import gtm from '@Utils/gtm/core';
import facebookPixel from '@Utils/facebookPixel';
import {AddToBasketMethod} from '@Utils/eventsUtilsTypes';
import {getAbTestType} from '@Features/storage/storageSelectors';

export const useVariantPickerAnalytics = (
  activity: IActivityProcessed,
  variant: IActivityVariantProcessed,
  orderNumber: number
) => {
  const isCheckout = !!useMatch(CHECKOUT_PATH);
  const affiliationHash = useSelector(getAffiliationHash);
  const facility = useSelector(getFacility)!;
  const {company, id: facilityId, name: facilityName} = facility;
  const {id: activityId, passType} = activity;
  const currentAbTestType = useSelector(getAbTestType);

  // Should be triggered with total variant basket items quantity
  const handleAmplitudeEvent = (numberOfTickets: number, addToBasketMethod?: AddToBasketMethod) => {
    const eventType = isCheckout ? EVENT_ADD_TO_BASKET_UPSELL : EVENT_ADD_TO_BASKET;

    pubsub.trigger(eventType, {
      facilityId,
      facilityName,
      numberOfTickets,
      industry: company.industry,
      affiliationHash,
      activityId,
      passType,
      orderNumber,
      language: parseLanguage(locale.locale),
      addToBasketMethod,
      abTest: eventType === EVENT_ADD_TO_BASKET ? currentAbTestType : undefined
    });
  };

  // Should be triggered with difference between previous and current quantity
  const handleGTMEvent = (quantity: number, action: 'add' | 'remove', onlineGroupId: number | null) => {
    if (action === 'add') {
      gtm.addToCart(facility, {activity, variant, quantity}, onlineGroupId);
    } else {
      gtm.removeFromCart(facility, {activity, variant, quantity}, onlineGroupId);
    }
  };

  // Should be triggered only with first add to cart. Quantity doesn't matter.
  // After remove variant items from order, it should be triggered again.
  const handleFBPixelEvent = () => {
    facebookPixel.postAddToCart(facility, {activity, variant});
  };

  return {
    handleAmplitudeEvent,
    handleGTMEvent,
    handleFBPixelEvent
  };
};

