import {IActivityVariantProcessed} from '@Consts/globals';
import {IBasketItemSelections} from '@Consts/types';
import {DASH_DAY_FORMAT, DASH_DAY_FORMAT_WITH_HOUR} from '@Utils/dayjs/const';
import {getTimezoneDateOrNull} from '@Utils/dayjs/dayjsUtils';
import directFrameMessage from '@Utils/directFrameMessage';
import {GTM_MESSAGE_NAME} from './core';

// Clear the previous ecommerce object.
export const clearEcommerce = () => {
  directFrameMessage({
    name: GTM_MESSAGE_NAME,
    gtmData: {ecommerce: null}
  });
};

export const getTotalPrice = (variant: IActivityVariantProcessed, quantity: number) => {
  const price =
    variant?.cost?.price ??
    variant?.datesCostMin?.price ??
    0;

  return parseFloat((price * quantity).toFixed(2));
};

export const getFormatAdmissionDate = (isWithHours: boolean, date: string | null, timezone: string) => {
  if (isWithHours) {
    return getTimezoneDateOrNull(date, timezone)?.format(DASH_DAY_FORMAT_WITH_HOUR) ?? null;
  } else {
    return getTimezoneDateOrNull(date, timezone)?.format(DASH_DAY_FORMAT) ?? null;
  }
};

export const getSelectionFormatAdmissionDate = (
  selections: IBasketItemSelections,
  timezone: string
) => {
  const {time, dates} = selections;
  const isWithHours = !!time?.date;
  const date = (time?.date || dates?.[0].date) ?? null;

  return getFormatAdmissionDate(isWithHours, date, timezone);
};

export const getBasketTotalPrice = (selections: IBasketItemSelections) => {
  const {time, dates} = selections;
  const isPricePerAdmissionDate = dates?.[0].group[0].isPricePerAdmissionDate;

  if (isPricePerAdmissionDate) {
    return dates.reduce((acc, cur) => acc + cur.metaData.totalPrice, 0);
  } else if (!isPricePerAdmissionDate && dates?.[0]) {
    return dates[0].metaData.totalPrice;
  } else if (time?.totalPrice) {
    return time?.totalPrice;
  }
};
export const getSelectionsVariantPrice = (variantId: number, selections: IBasketItemSelections) => {
  const {time, dates} = selections;

  if (time?.variants) {
    return time?.variants.find(variant => variant.variantId === variantId)?.cost.price ?? null;
  } else if (dates?.[0].group?.[0].variants) {
    if (dates?.[0].group[0].isPricePerAdmissionDate && dates?.[0]) {
      return dates.reduce(
        (acc, cur) => acc + (
          cur.group?.[0].variants.find(variant => variant.variantId === variantId)?.cost.price ?? 0), 0) ?? null;
    } else {
      return dates?.[0].group[0].variants.find(variant => variant.variantId === variantId)?.cost.price ?? null;
    }
  } else {
    return null;
  }
};
