/* global __WIDGET_AMPLITUDE_ID__ */
import * as amplitude from '@amplitude/analytics-browser';
import pubsub from './pubsub';
import {isWidgetBusiness} from './widgetType';
import {
  getEventProperties,
  getUserId,
  getUserProperties,
  getUserType
} from './eventsUtils';
import {initializeConfig} from './amplitude/config';

export const EVENT_INITIALIZE = 'EVENT_INITIALIZE';
export const EVENT_INITIALIZE_ERROR = 'EVENT_INITIALIZE_ERROR';
export const EVENT_INVALID_SCRIPT_ERROR = 'EVENT_INVALID_SCRIPT_ERROR';
export const EVENT_VIEW_ACTIVITIES_FILTER_TAG = 'EVENT_VIEW_ACTIVITIES_FILTER_TAG';
export const EVENT_VIEW_ACTIVITIES = 'EVENT_VIEW_ACTIVITIES';
export const EVENT_VIEW_ACTIVITIES_DETAILS = 'EVENT_VIEW_ACTIVITIES_DETAILS';
export const EVENT_VIEW_ACTIVITIES_DETAILS_HIDE = 'EVENT_VIEW_ACTIVITIES_DETAILS_HIDE';
export const EVENT_LOADED_WITH_GROUP_ID = 'EVENT_LOADED_WITH_GROUP_ID';
export const EVENT_TICKET_CREATED = 'EVENT_TICKET_CREATED';
export const EVENT_SUBMITTED_FORM = 'EVENT_SUBMITTED_FORM';
export const EVENT_RECIEVED_PAYMENT_LINK = 'EVENT_RECIEVED_PAYMENT_LINK';
export const EVENT_TICKET_ERROR = 'EVENT_TICKET_ERROR';
export const EVENT_ADD_TO_BASKET = 'EVENT_ADD_TO_BASKET';
export const EVENT_ADD_TO_BASKET_UPSELL = 'EVENT_ADD_TO_BASKET_UPSELL';
export const EVENT_VIEW_UPSELL_OFFER = 'EVENT_VIEW_UPSELL_OFFER';
export const EVENT_BUY_UPSELL_COMPLETED = 'EVENT_BUY_UPSELL_COMPLETED';
export const EVENT_CLICKED_BACK_TO_LIST = 'EVENT_CLICKED_BACK_TO_LIST';
export const EVENT_SHOW_BUYING_PREVIEW = 'EVENT_SHOW_BUYING_PREVIEW';
export const EVENT_SHOW_SUCCESS_SCREEN = 'EVENT_SHOW_SUCCESS_SCREEN';
export const EVENT_UNFOLD_DISCOUNT_BOX = 'EVENT_UNFOLD_DISCOUNT_BOX';
export const EVENT_DISCOUNT_RESULT = 'EVENT_DISCOUNT_RESULT';
export const EVENT_BUBBLE_VIEW = 'EVENT_BUBBLE_VIEW';
export const EVENT_BUBBLE_CLICKED = 'EVENT_BUBBLE_CLICKED';
export const EVENT_SHOW_SUCCESS_SCREEN_TICKETS = 'EVENT_SHOW_SUCCESS_SCREEN_TICKETS';
export const EVENT_CLICK_ON_SEND_TICKET_TO_USER = 'EVENT_CLICK_ON_SEND_TICKET_TO_USER';
export const EVENT_GETS_SENDING_EMAIL_CONFIRMATION = 'EVENT_GETS_SENDING_EMAIL_CONFIRMATION';
export const EVENT_CLICK_ON_DOWNLOAD_TICKET_BUTTON = 'EVENT_CLICK_ON_DOWNLOAD_TICKET_BUTTON';
export const EVENT_CLICK_ON_ADD_TO_APPLE_WALLET = 'EVENT_CLICK_ON_ADD_TO_APPLE_WALLET';
export const EVENT_CLICK_ON_ADD_TO_GOOGLE_WALLET = 'EVENT_CLICK_ON_ADD_TO_GOOGLE_WALLET';
export const EVENT_VIEW_ADDITIONS = 'EVENT_VIEW_ADDITIONS';
export const EVENT_VIEW_PARTICIPANTS_GROUP = 'EVENT_VIEW_PARTICIPANTS_GROUP';
export const EVENT_VIEW_QUESTION = 'EVENT_VIEW_QUESTION';
export const EVENT_INVALID_PARCEL_LOCKER_ADDRESS = 'EVENT_INVALID_PARCEL_LOCKER_ADDRESS';
export const EVENT_SEES_SHIPPING_METHODS = 'EVENT_SEES_SHIPPING_METHODS';
export const EVENT_SELECTS_SHIPPING_METHOD = 'EVENT_SELECTS_SHIPPING_METHOD';
export const EVENT_NO_SHIPPING_METHODS_ERROR = 'EVENT_NO_SHIPPING_METHODS_ERROR';
export const EVENT_QUESTION_COMPLETE = 'EVENT_QUESTION_COMPLETE';
export const SET_USER_ID = 'SET_USER_ID';
export const EVENT_ADDITIONS_ERROR = 'EVENT_ADDITIONS_ERROR';
export const EVENT_QUESTIONS_ERROR = 'EVENT_QUESTIONS_ERROR';
export const EVENT_ACTIVITY_ADMISSION_DATES_ERROR = 'EVENT_ACTIVITY_ADMISSION_DATES_ERROR';
export const EVENT_POST_MESSAGE_NOT_SUPPORTED = 'EVENT_POST_MESSAGE_NOT_SUPPORTED';
export const EVENT_SELECTS_LANGUAGE = 'EVENT_SELECTS_LANGUAGE';
export const EVENT_BUY_CROSS_SELL_LOADED = 'EVENT_BUY_CROSS_SELL_LOADED';
export const EVENT_BUY_CROSS_SELL_CLICKED = 'EVENT_BUY_CROSS_SELL_CLICKED';
export const EVENT_BUY_LOADED_ACTIVITY_FROM_LOCAL_STORAGE = 'EVENT_BUY_LOADED_ACTIVITY_FROM_LOCAL_STORAGE';
export const EVENT_BUY_SHOW_ERROR_DEPENDENT_VARIANT = 'EVENT_BUY_SHOW_ERROR_DEPENDENT_VARIANT';
export const EVENT_BUY_CLOSING_DIALOG_APPEARS = 'EVENT_BUY_CLOSING_DIALOG_APPEARS';
export const EVENT_BUY_CLOSING_DIALOG_CONFIRMED = 'EVENT_BUY_CLOSING_DIALOG_CONFIRMED';
export const EVENT_BUY_CLOSING_DIALOG_CANCELED = 'EVENT_BUY_CLOSING_DIALOG_CANCELED';
export const EVENT_BUY_TICKETS_USED_AFTER_PAYMENT = 'EVENT_BUY_TICKETS_USED_AFTER_PAYMENT';
export const EVENT_BUY_TICKETS_USED_AFTER_PAYMENT_WITH_ERROR = 'EVENT_BUY_TICKETS_USED_AFTER_PAYMENT_WITH_ERROR';
export const EVENT_BUY_CLOSING_BUYING_PREVIEW_DIALOG_APPEARS = 'EVENT_BUY_CLOSING_BUYING_PREVIEW_DIALOG_APPEARS';
export const EVENT_BUY_CLOSING_BUYING_PREVIEW_DIALOG_CONFIRMED = 'EVENT_BUY_CLOSING_BUYING_PREVIEW_DIALOG_CONFIRMED';
export const EVENT_BUY_CLOSING_BUYING_PREVIEW_DIALOG_CANCELED = 'EVENT_BUY_CLOSING_BUYING_PREVIEW_DIALOG_CANCELED';
export const EVENT_BUY_ADMISSION_DATES_SEES_DATES = 'EVENT_BUY_ADMISSION_DATES_SEES_DATES';
export const EVENT_BUY_ADMISSION_DATES_CHANGES_MONTH = 'EVENT_BUY_ADMISSION_DATES_CHANGES_MONTH';
export const EVENT_BUY_ADMISSION_DATES_SELECTS_AVAILABLE_DATE = 'EVENT_BUY_ADMISSION_DATES_SELECTS_AVAILABLE_DATE';
export const EVENT_BUY_ADMISSION_DATES_SEES_HOURS = 'EVENT_BUY_ADMISSION_DATES_SEES_HOURS';
export const EVENT_BUY_ADMISSION_DATES_SELECTS_AVAILABLE_HOUR = 'EVENT_BUY_ADMISSION_DATES_SELECTS_AVAILABLE_HOUR';
export const EVENT_BUY_ADMISSION_DATES_COMPLETED = 'EVENT_BUY_ADMISSION_DATES_COMPLETED';
export const EVENT_BUY_SEES_SCREEN_WITH_PAYMENT_LINK_GENERATED = 'EVENT_BUY_SEES_SCREEN_WITH_PAYMENT_LINK_GENERATED';
export const EVENT_BUY_CLICK_ON_RETRY_PAYMENT = 'EVENT_BUY_CLICK_ON_RETRY_PAYMENT';
export const EVENT_BUBBLE_START_LOADING = 'EVENT_BUBBLE_START_LOADING';
export const EVENT_SHOW_VARIANTS_LIST = 'EVENT_SHOW_VARIANTS_LIST';
export const EVENT_HIDE_VARIANTS_LIST = 'EVENT_HIDE_VARIANTS_LIST';
export const EVENT_INVOICE_FORM_ENABLED = 'EVENT_INVOICE_FORM_ENABLED';
export const EVENT_INVOICE_FORM_DISABLED = 'EVENT_INVOICE_FORM_DISABLED';
export const EVENT_INVOICE_FORM_NIP_FILLED = 'EVENT_INVOICE_FORM_NIP_FILLED';
export const EVENT_INVOICE_FORM_COMPANY_DATA_LOAD = 'EVENT_INVOICE_FORM_COMPANY_DATA_LOAD';
export const EVENT_SEES_FINAL_CHECKOUT_FORM = 'EVENT_SEES_FINAL_CHECKOUT_FORM';

export const initialize = (amplitudeId, optOut) => {
  const amplitudeApiKey = amplitudeId ?? __WIDGET_AMPLITUDE_ID__;

  initializeConfig(amplitudeApiKey, optOut);

  pubsub.on(EVENT_INITIALIZE, ({
    widgetType,
    facilityId,
    facilityName,
    url,
    companyUserId,
    companyId,
    userName,
    companyName,
    language,
    parentReferrer
  }) => {
    const currentUserId = amplitude.getUserId();
    const id = getUserId({widgetType, companyUserId, currentUserId});

    amplitude.setUserId(id);
    const identifyObj = new amplitude.Identify();

    const userProperties = getUserProperties({
      userType: getUserType(widgetType),
      companyUserId,
      companyId,
      userName,
      companyName
    });

    Object.entries(userProperties).forEach(([key, value]) => {
      identifyObj.set(key, value);
    });

    amplitude.identify(identifyObj);

    amplitude.logEvent(
      'Widget - Start loading',
      getEventProperties({
        facilityId,
        facilityName,
        websiteUrl: url || 'empty',
        language,
        referrer: parentReferrer
      }));
  });

  pubsub.on(SET_USER_ID, ({
    widgetType,
    orderBuyerHash,
    businessBuyerCompanyId,
    userName,
    companyName,
    companyUserId,
    language
  }) => {
    const id = getUserId({widgetType, companyUserId, orderBuyerHash});
    let userProperties = {};

    if (isWidgetBusiness(widgetType)) {
      userProperties = getUserProperties({
        companyUserId,
        companyID: businessBuyerCompanyId,
        companyName,
        userName,
        language
      });
    }

    amplitude.setUserId(id);
    const identifyObj = new amplitude.Identify();

    Object.entries(userProperties).forEach(([key, value]) => {
      identifyObj.set(key, value);
    });

    amplitude.identify(identifyObj);
  });

  pubsub.on(EVENT_VIEW_ACTIVITIES, ({
    facilityId,
    facilityName,
    numberOfActivities,
    industry,
    affiliationHash,
    language,
    onlineGroupId,
    activityId,
    url,
    widgetMode,
    enabledPaymentMethods,
    abTest
  }) => {
    amplitude.logEvent('View activities - List',
      getEventProperties({
        facilityId,
        facilityName,
        results: numberOfActivities,
        industry,
        affiliationHash,
        onlineGroupId,
        language,
        activityId,
        url,
        widgetMode,
        enabledPaymentMethods,
        abTest
      })
    );
  });

  pubsub.on(EVENT_VIEW_ACTIVITIES_FILTER_TAG, ({
    facilityId, facilityName, industry, affiliationHash, language, tag
  }) => {
    amplitude.logEvent('View activities - Filter by tags',
      getEventProperties({
        facilityId,
        facilityName,
        industry,
        affiliationHash,
        language,
        tag
      })
    );
  });

  pubsub.on(EVENT_BUY_CROSS_SELL_LOADED, ({
    facilityId, facilityName, industry, affiliationHash, language, isFirstLoading
  }) => {
    amplitude.logEvent('Buy - Cross-sell frame loaded on after payment screen',
      getEventProperties({
        facilityId,
        facilityName,
        industry,
        affiliationHash,
        language,
        isFirstLoading
      })
    );
  });

  pubsub.on(EVENT_BUY_CROSS_SELL_CLICKED, ({
    facilityId, facilityName, industry, affiliationHash, language, isFirstLoading
  }) => {
    amplitude.logEvent('Buy - Cross-sell frame clicked on after payment screen',
      getEventProperties({
        facilityId,
        facilityName,
        industry,
        affiliationHash,
        language,
        isFirstLoading
      })
    );
  });

  pubsub.on(EVENT_VIEW_ACTIVITIES_DETAILS, ({
    facilityId, facilityName, industry, affiliationHash, activityName, activityId
  }) => {
    amplitude.logEvent('View activities - Expands details',
      getEventProperties({
        facilityId,
        facilityName,
        industry,
        affiliationHash,
        activityName,
        activityId
      })
    );
  });

  pubsub.on(EVENT_VIEW_ACTIVITIES_DETAILS_HIDE, ({
    facilityId, facilityName, industry, affiliationHash, activityName, activityId
  }) => {
    amplitude.logEvent('View activities - Hide details',
      getEventProperties({
        facilityId,
        facilityName,
        industry,
        affiliationHash,
        activityName,
        activityId
      })
    );
  });

  pubsub.on(EVENT_TICKET_CREATED, ({
    facilityId,
    facilityName,
    industry,
    affiliationHash,
    invoiceDataSubmitted,
    language,
    revenueTotal,
    revenueFromUpsell,
    currency,
    isAgreeMarketingConsents,
    widgetMode,
    paymentMethod,
    abTest
  }) => {
    amplitude.logEvent('Buy - Ticket created',
      getEventProperties({
        facilityId,
        facilityName,
        industry,
        affiliationHash,
        invoiceDataSubmitted: invoiceDataSubmitted ? 'yes' : 'no',
        language,
        revenueTotal,
        revenueFromUpsell,
        currency,
        ...isAgreeMarketingConsents !== undefined &&
        {isAgreeMarketingConsents: isAgreeMarketingConsents ? 'yes' : 'no'},
        widgetMode,
        paymentMethod,
        abTest
      })
    );
  });

  pubsub.on(EVENT_SUBMITTED_FORM, ({
    facilityId,
    facilityName,
    numberOfTickets,
    industry,
    affiliationHash,
    invoiceDataSubmitted,
    orderDetails,
    paymentItems,
    language
  }) => {
    amplitude.logEvent('Buy - Submits buying form',
      getEventProperties({
        facilityId,
        facilityName,
        numberOfTickets,
        industry,
        affiliationHash,
        invoiceDataSubmitted: invoiceDataSubmitted ? 'yes' : 'no',
        formValues: orderDetails,
        requestBody: paymentItems,
        language
      })
    );
  });

  pubsub.on(EVENT_SEES_FINAL_CHECKOUT_FORM, ({
    facilityId,
    facilityName,
    industry,
    affiliationHash,
    numberOfTickets,
    enabledPaymentMethods,
    language,
    onlineGroupId,
    isMarketingConsentsEnabled,
    marketingConsentsTitle,
    marketingConsentsDescription
  }) => {
    amplitude.logEvent('Buy - Sees final checkout form',
      getEventProperties({
        facilityId,
        facilityName,
        industry,
        affiliationHash,
        numberOfTickets,
        enabledPaymentMethods,
        language,
        onlineGroupId,
        isMarketingConsentsEnabled,
        marketingConsentsTitle,
        marketingConsentsDescription
      })
    );
  });

  pubsub.on(EVENT_RECIEVED_PAYMENT_LINK, ({facilityId, code, order, language}) => {
    const {id, state, payUrl, orderBuyerHash} = order;

    amplitude.logEvent('Buy - Recieves payment link',
      getEventProperties({
        facilityId,
        orderCode: code,
        orderId: id,
        orderState: state,
        orderPaymentUrl: payUrl,
        userId: orderBuyerHash,
        language
      })
    );
  });

  pubsub.on(EVENT_INITIALIZE_ERROR, ({message}) => {
    amplitude.logEvent('Initialize - Gets error on initial data fetch',
      getEventProperties({
        error: message
      })
    );
  });

  pubsub.on(EVENT_INVALID_SCRIPT_ERROR, ({facilityId, facilityName, url}) => {
    amplitude.logEvent('Initialize - Gets error on widget.js script loading',
      getEventProperties({
        error: 'script widget.js not found',
        facilityId,
        facilityName,
        websiteUrl: url
      }));
  });

  pubsub.on(EVENT_TICKET_ERROR, ({
    facilityId, facilityName, message, industry, affiliationHash, data, responseData, errors, language
  }) => {
    amplitude.logEvent('Buy - Gets error on ticket creation',
      getEventProperties({
        facilityId,
        facilityName,
        industry,
        affiliationHash,
        error: message,
        formData: data,
        validationError: errors,
        responseData,
        language
      })
    );
  });

  pubsub.on(EVENT_ADDITIONS_ERROR, ({
    id, name, activityId, error, fetchingTime
  }) => {
    amplitude.logEvent('Additions - could not be fetched',
      getEventProperties({
        facilityId: id,
        facilityName: name,
        activityId,
        errorTitle: error?.data?.title || '-',
        errorType: error?.data?.type || '-',
        errorStatus: error?.status || '-',
        fetchingTime: fetchingTime || '-'
      })
    );
  });

  pubsub.on(EVENT_QUESTIONS_ERROR, ({
    id, name, activityId, error, fetchingTime
  }) => {
    amplitude.logEvent('Questions - could not be fetched',
      getEventProperties({
        facilityId: id,
        facilityName: name,
        activityId,
        errorTitle: error?.data?.title || '-',
        errorType: error?.data?.type || '-',
        errorStatus: error?.status || '-',
        fetchingTime: fetchingTime || '-'
      })
    );
  });

  pubsub.on(EVENT_ACTIVITY_ADMISSION_DATES_ERROR, ({
    id, name, activityId, error, since, until, fetchingTime
  }) => {
    amplitude.logEvent('Admission dates - could not be fetched',
      getEventProperties({
        facilityId: id,
        facilityName: name,
        activityId,
        errorTitle: error?.data?.title || '-',
        errorType: error?.data?.type || '-',
        errorStatus: error?.status || '-',
        datesSince: since,
        datesUtil: until,
        fetchingTime: fetchingTime || '-'
      })
    );
  });

  pubsub.on(EVENT_ADD_TO_BASKET, ({
    facilityId,
    facilityName,
    numberOfTickets,
    industry,
    affiliationHash,
    activityId,
    passType,
    orderNumber,
    language,
    abTest,
    addToBasketMethod
  }) => {
    amplitude.logEvent('Buy - Chooses activity to buy',
      getEventProperties({
        facilityId,
        facilityName,
        numberOfTickets,
        industry,
        affiliationHash,
        activityId,
        passType,
        priorityFromTopToBottom: orderNumber,
        language,
        abTest,
        addToBasketMethod
      })
    );
  });

  pubsub.on(EVENT_ADD_TO_BASKET_UPSELL, ({
    facilityId,
    facilityName,
    numberOfTickets,
    industry,
    affiliationHash,
    activityId,
    passType,
    orderNumber,
    language,
    abTest,
    addToBasketMethod
  }) => {
    amplitude.logEvent('Buy - Adds activity from upsell',
      getEventProperties({
        facilityId,
        facilityName,
        numberOfTickets,
        industry,
        affiliationHash,
        activityId,
        passType,
        priorityFromTopToBottom: orderNumber,
        language,
        abTest,
        addToBasketMethod
      })
    );
  });

  pubsub.on(EVENT_VIEW_UPSELL_OFFER, ({
    facilityId,
    facilityName,
    numberOfActivities,
    industry,
    affiliationHash,
    language,
    onlineGroupId,
    numberOfTickets
  }) => {
    amplitude.logEvent('Buy - View activities from upsell',
      getEventProperties({
        facilityId,
        facilityName,
        results: numberOfActivities,
        industry,
        affiliationHash,
        onlineGroupId,
        language,
        numberOfTickets
      })
    );
  });

  pubsub.on(EVENT_BUY_UPSELL_COMPLETED, ({
    facilityId,
    facilityName,
    industry,
    affiliationHash,
    language,
    onlineGroupId,
    numberOfTickets
  }) => {
    amplitude.logEvent('Buy - Upsell - Completed',
      getEventProperties({
        facilityId,
        facilityName,
        industry,
        affiliationHash,
        onlineGroupId,
        language,
        numberOfTickets
      })
    );
  });

  pubsub.on(EVENT_SELECTS_LANGUAGE, ({
    facilityId, facilityName, originalLanguage, language
  }) => {
    amplitude.logEvent('Selects language',
      getEventProperties({
        facilityId,
        facilityName,
        language,
        originalLanguage
      })
    );
  });

  pubsub.on(EVENT_CLICKED_BACK_TO_LIST, ({
    facilityId, facilityName, industry, affiliationHash, language, isFirstLoading
  }) => {
    amplitude.logEvent('Buy - Clicks on buy another ticket',
      getEventProperties({
        facilityId,
        facilityName,
        industry,
        affiliationHash,
        language,
        isFirstLoading
      })
    );
  });

  pubsub.on(EVENT_SHOW_BUYING_PREVIEW, ({
    facilityId,
    facilityName,
    industry,
    affiliationHash,
    language,
    isAgreeMarketingConsents,
    numberOfTickets,
    enabledPaymentMethods,
    abTest
  }) => {
    amplitude.logEvent('Buy - Sees buying preview',
      getEventProperties({
        facilityId,
        facilityName,
        industry,
        affiliationHash,
        language,
        numberOfTickets,
        enabledPaymentMethods,
        abTest,
        ...isAgreeMarketingConsents !== undefined && {
          isAgreeMarketingConsents: isAgreeMarketingConsents ? 'yes' : 'no'
        }
      })
    );
  });

  pubsub.on(EVENT_SHOW_SUCCESS_SCREEN, ({
    facilityId,
    facilityName,
    industry,
    affiliationHash,
    language,
    isFirstLoading,
    abTest
  }) => {
    amplitude.logEvent('Buy - Sees after payment screen',
      getEventProperties({
        facilityId,
        facilityName,
        industry,
        affiliationHash,
        language,
        isFirstLoading,
        abTest
      })
    );
  });

  pubsub.on(EVENT_SHOW_SUCCESS_SCREEN_TICKETS, ({
    facilityId,
    facilityName,
    industry,
    affiliationHash,
    language,
    isFirstLoading
  }) => {
    amplitude.logEvent('Buy - Tickets loaded on after payment screen',
      getEventProperties({
        facilityId,
        facilityName,
        industry,
        affiliationHash,
        language,
        isFirstLoading
      })
    );
  });

  pubsub.on(EVENT_CLICK_ON_SEND_TICKET_TO_USER, ({errors, isFirstLoading}) => {
    const properties = errors ? getEventProperties({
      error: JSON.stringify(errors),
      result: 'error',
      isFirstLoading
    }) : getEventProperties({
      result: 'ok',
      isFirstLoading
    });

    amplitude.logEvent('Buy - Clicks on send ticket to user\'s email', properties);
  });

  pubsub.on(EVENT_GETS_SENDING_EMAIL_CONFIRMATION, ({result, isFirstLoading}) => {
    amplitude.logEvent('Buy - Gets result of sending to email',
      getEventProperties({
        result,
        isFirstLoading
      })
    );
  });

  pubsub.on(EVENT_CLICK_ON_DOWNLOAD_TICKET_BUTTON, ({language, isFirstLoading}) => {
    amplitude.logEvent('Buy - Clicks on download tickets',
      getEventProperties({
        language,
        isFirstLoading
      }));
  });

  pubsub.on(EVENT_CLICK_ON_ADD_TO_APPLE_WALLET, ({
    source, saleType, language, isFirstLoading
  }) => {
    amplitude.logEvent('Buy - Clicks on Add to Apple Wallet',
      getEventProperties({
        source,
        saleType,
        language,
        isFirstLoading
      })
    );
  });

  pubsub.on(EVENT_CLICK_ON_ADD_TO_GOOGLE_WALLET, ({
    source, saleType, language, isFirstLoading
  }) => {
    amplitude.logEvent('Buy - Clicks on Add to Google Wallet',
      getEventProperties({
        source,
        saleType,
        language,
        isFirstLoading
      })
    );
  });

  pubsub.on(EVENT_UNFOLD_DISCOUNT_BOX, ({
    facilityId, facilityName, industry, affiliationHash
  }) => {
    amplitude.logEvent('Discount - Unfolds discount code box',
      getEventProperties({
        facilityId,
        facilityName,
        industry,
        affiliationHash
      })
    );
  });

  pubsub.on(EVENT_DISCOUNT_RESULT, ({
    facilityId, facilityName, industry, affiliationHash, result, message
  }) => {
    amplitude.logEvent('Discount - Gets result',
      getEventProperties({
        facilityId,
        facilityName,
        industry,
        affiliationHash,
        result,
        message
      })
    );
  });

  pubsub.on(EVENT_VIEW_ADDITIONS, ({
    numberOfAdditions, facilityId, facilityName, industry, affiliationHash, language
  }) => {
    amplitude.logEvent('Buy - Additions - Sees additions',
      getEventProperties({
        additions: numberOfAdditions,
        facilityId,
        facilityName,
        industry,
        affiliationHash,
        language
      })
    );
  });

  pubsub.on(EVENT_VIEW_PARTICIPANTS_GROUP, ({
    facilityId, facilityName, language, basketItems
  }) => {
    amplitude.logEvent('Buy - Patricipants group - Sees patricipants group',
      getEventProperties({
        facilityId,
        facilityName,
        language,
        basketItems
      })
    );
  });

  pubsub.on(EVENT_VIEW_QUESTION, ({
    text, type, isRequired, facilityId, facilityName, industry, affiliationHash, language
  }) => {
    amplitude.logEvent('Buy - Questions - Sees questions',
      getEventProperties({
        questionText: text,
        questionType: type,
        facilityId,
        facilityName,
        industry,
        affiliationHash,
        required: isRequired ? 'true' : 'false',
        language
      })
    );
  });

  pubsub.on(EVENT_SEES_SHIPPING_METHODS, ({
    facilityId, facilityName, items, shippingMethods, language
  }) => {
    amplitude.logEvent('Buy - Shipping methods - Sees shipping methods',
      getEventProperties({
        facilityId,
        facilityName,
        items,
        shippingMethods,
        language
      })
    );
  });

  pubsub.on(EVENT_NO_SHIPPING_METHODS_ERROR, ({
    facilityId, facilityName, language
  }) => {
    amplitude.logEvent('Buy - Shipping methods - No shipping methods error',
      getEventProperties({
        facilityId,
        facilityName,
        language
      })
    );
  });

  pubsub.on(EVENT_SELECTS_SHIPPING_METHOD, ({
    facilityId, facilityName, items, shippingMethods, language, selectedShippingMethodId
  }) => {
    amplitude.logEvent('Buy - Shipping methods - Selects a shipping method',
      getEventProperties({
        facilityId,
        facilityName,
        items,
        shippingMethods,
        language,
        selectedShippingMethodId
      })
    );
  });

  pubsub.on(EVENT_INVALID_PARCEL_LOCKER_ADDRESS, ({
    facilityId, facilityName, items, shippingMethods
  }) => {
    amplitude.logEvent('Shipping methods - invalid data from parcel locker geowidget',
      getEventProperties({
        facilityId,
        facilityName,
        items,
        shippingMethods
      })
    );
  });

  pubsub.on(EVENT_QUESTION_COMPLETE, ({
    text, type, isRequired, facilityId, facilityName, industry, affiliationHash, result, error, answer, language
  }) => {
    amplitude.logEvent('Buy - Questions - Complete',
      getEventProperties({
        questionText: text,
        required: isRequired ? 'true' : 'false',
        questionType: type,
        facilityId,
        facilityName,
        industry,
        affiliationHash,
        result,
        error,
        answerText: answer,
        language
      })
    );
  });

  pubsub.on(EVENT_LOADED_WITH_GROUP_ID, ({
    facilityId, groupId, facilityName
  }) => {
    amplitude.logEvent('Widget loaded with group ID parameter',
      getEventProperties({
        facilityId,
        facilityName,
        groupId
      })
    );
  });

  pubsub.on(EVENT_BUBBLE_VIEW, ({facilityId, url, destinationUrl, uuid, configuration, disabled}) => {
    amplitude.setUserId('1');
    amplitude.logEvent('Bubble - View',
      getEventProperties({
        facilityId,
        url,
        destinationUrl,
        uuid,
        configuration,
        disabled
      })
    );
  });

  pubsub.on(EVENT_BUBBLE_START_LOADING, ({facilityId, url, destinationUrl, uuid, disabled}) => {
    amplitude.setUserId('1');
    amplitude.logEvent('Bubble - Start loading',
      getEventProperties({
        facilityId,
        url,
        destinationUrl,
        uuid,
        disabled
      })
    );
  });

  pubsub.on(EVENT_BUBBLE_CLICKED, ({facilityId, url, destinationUrl, uuid, configuration, disabled}) => {

    //** User Id must be set for this event because it could have been overwritten by widget opened in modal mode */
    //** After close widget in modal mode amplitude instance will store user id from widget */
    amplitude.setUserId('1');

    amplitude.logEvent('Bubble - Clicked',
      getEventProperties({
        facilityId,
        url,
        destinationUrl,
        uuid,
        configuration,
        disabled
      })
    );
  });

  pubsub.on(EVENT_POST_MESSAGE_NOT_SUPPORTED, ({url}) => {
    amplitude.logEvent('[DEV]: postMessage not supported',
      getEventProperties({
        url
      })
    );
  });

  pubsub.on(EVENT_BUY_LOADED_ACTIVITY_FROM_LOCAL_STORAGE, ({
    facilityId,
    facilityName,
    numberOfTickets,
    industry,
    affiliationHash,
    language
  }) => {
    amplitude.logEvent('Buy - Loaded activity to buy from localstorage',
      getEventProperties({
        facilityId,
        facilityName,
        numberOfTickets,
        industry,
        affiliationHash,
        language
      })
    );
  });

  pubsub.on(EVENT_BUY_SHOW_ERROR_DEPENDENT_VARIANT, ({
    facilityId,
    facilityName,
    chosenVariants,
    affiliationHash,
    missingVariants
  }) => {
    amplitude.logEvent('Buy - Show error on dependent variant missing',
      getEventProperties({
        facilityId,
        facilityName,
        chosenVariants,
        affiliationHash,
        missingVariants
      })
    );
  });

  pubsub.on(EVENT_BUY_CLOSING_DIALOG_APPEARS, ({
    facilityId,
    facilityName,
    url
  }) => {
    amplitude.logEvent('Buy - Closing dialog - Dialog appears',
      getEventProperties({
        facilityId,
        facilityName,
        url
      })
    );
  });

  pubsub.on(EVENT_BUY_CLOSING_DIALOG_CONFIRMED, async ({
    facilityId,
    facilityName,
    url,
    callback
  }) => {
    const response = amplitude.logEvent('Buy - Closing dialog - Closing confirmed',
      getEventProperties({
        facilityId,
        facilityName,
        url
      })
    );

    // trigger callback when amplitude is not initiated
    if (!amplitude.getSessionId()) {
      callback();
    }

    response.promise.finally(() => callback());
  });

  pubsub.on(EVENT_BUY_CLOSING_DIALOG_CANCELED, ({
    facilityId,
    facilityName,
    url
  }) => {
    amplitude.logEvent('Buy - Closing dialog - Closing canceled',
      getEventProperties({
        facilityId,
        facilityName,
        url
      })
    );
  });

  pubsub.on(EVENT_BUY_TICKETS_USED_AFTER_PAYMENT, ({
    facilityId,
    facilityName,
    isFirstLoading,
    orderId,
    affiliationHash,
    industry
  }) => {
    amplitude.logEvent('Buy - Tickets used on after payment screen',
      getEventProperties({
        facilityId,
        facilityName,
        isFirstLoading,
        orderId,
        affiliationHash,
        industry
      })
    );
  });

  pubsub.on(EVENT_BUY_TICKETS_USED_AFTER_PAYMENT_WITH_ERROR, ({
    facilityId,
    facilityName,
    isFirstLoading,
    orderId,
    affiliationHash,
    industry,
    error
  }) => {
    amplitude.logEvent('Buy - Error when use tickets on after payment screen',
      getEventProperties({
        facilityId,
        facilityName,
        isFirstLoading,
        orderId,
        affiliationHash,
        industry,
        error
      })
    );
  });

  pubsub.on(EVENT_BUY_CLOSING_BUYING_PREVIEW_DIALOG_APPEARS, ({
    facilityId,
    facilityName,
    url,
    source
  }) => {
    amplitude.logEvent('Buy - Closing buying preview dialog - Dialog appears',
      getEventProperties({
        facilityId,
        facilityName,
        url,
        source
      })
    );
  });

  pubsub.on(EVENT_BUY_CLOSING_BUYING_PREVIEW_DIALOG_CONFIRMED, ({
    facilityId,
    facilityName,
    url
  }) => {
    amplitude.logEvent('Buy - Closing buying preview dialog - Closing confirmed',
      getEventProperties({
        facilityId,
        facilityName,
        url
      })
    );
  });

  pubsub.on(EVENT_BUY_CLOSING_BUYING_PREVIEW_DIALOG_CANCELED, ({
    facilityId,
    facilityName,
    url
  }) => {
    amplitude.logEvent('Buy - Closing buying preview dialog - Closing canceled',
      getEventProperties({
        facilityId,
        facilityName,
        url
      })
    );
  });

  pubsub.on(EVENT_BUY_ADMISSION_DATES_SEES_DATES, ({
    facilityId,
    facilityName,
    industry,
    affiliationHash,
    activityId,
    activityName,
    chosenVariants,
    language,
    firstAdmissionDate,
    userNeedToChooseHour,
    numberOfAvailableDays,
    abTest
  }) => {
    amplitude.logEvent('Buy - Admission dates - Sees admission dates',
      getEventProperties({
        facilityId,
        facilityName,
        industry,
        affiliationHash,
        activityId,
        activityName,
        chosenVariants,
        language,
        firstAdmissionDate,
        userNeedToChooseHour: userNeedToChooseHour ? 'yes' : 'no',
        numberOfAvailableDays,
        abTest
      })
    );
  });

  pubsub.on(EVENT_BUY_ADMISSION_DATES_CHANGES_MONTH, ({
    facilityId,
    facilityName,
    industry,
    affiliationHash,
    activityId,
    activityName,
    chosenVariants,
    language,
    month,
    numberOfAvailableDays
  }) => {
    amplitude.logEvent('Buy - Admission dates - Changes month',
      getEventProperties({
        facilityId,
        facilityName,
        industry,
        affiliationHash,
        activityId,
        activityName,
        chosenVariants,
        language,
        month,
        numberOfAvailableDays
      })
    );
  });

  pubsub.on(EVENT_BUY_ADMISSION_DATES_SELECTS_AVAILABLE_DATE, ({
    facilityId,
    facilityName,
    industry,
    affiliationHash,
    activityId,
    activityName,
    chosenVariants,
    language,
    admissionDateDay,
    numberOfSelectedDates,
    admissionDateDescription,
    bookingWindow
  }) => {
    amplitude.logEvent('Buy - Admission dates - Selects available admission date',
      getEventProperties({
        facilityId,
        facilityName,
        industry,
        affiliationHash,
        activityId,
        activityName,
        chosenVariants,
        language,
        admissionDateDay,
        numberOfSelectedDates,
        admissionDateDescription,
        bookingWindow
      })
    );
  });

  pubsub.on(EVENT_BUY_ADMISSION_DATES_SEES_HOURS, ({
    facilityId,
    facilityName,
    industry,
    affiliationHash,
    activityId,
    activityName,
    chosenVariants,
    language,
    numberOfAvailableHours
  }) => {
    amplitude.logEvent('Buy - Admission dates - Sees admission hours',
      getEventProperties({
        facilityId,
        facilityName,
        industry,
        affiliationHash,
        activityId,
        activityName,
        chosenVariants,
        language,
        numberOfAvailableHours
      })
    );
  });

  pubsub.on(EVENT_BUY_ADMISSION_DATES_SELECTS_AVAILABLE_HOUR, ({
    facilityId,
    facilityName,
    industry,
    affiliationHash,
    activityId,
    activityName,
    chosenVariants,
    language,
    admissionDateHour,
    admissionDateDescription
  }) => {
    amplitude.logEvent('Buy - Admission dates - Selects available admission hour',
      getEventProperties({
        facilityId,
        facilityName,
        industry,
        affiliationHash,
        activityId,
        activityName,
        chosenVariants,
        language,
        admissionDateHour,
        admissionDateDescription
      })
    );
  });

  pubsub.on(EVENT_BUY_ADMISSION_DATES_COMPLETED, ({
    facilityId,
    facilityName,
    industry,
    affiliationHash,
    activityId,
    activityName,
    chosenVariants,
    language,
    admissionDateDay,
    numberOfSelectedDates,
    admissionDateHour,
    result,
    error,
    abTest
  }) => {
    amplitude.logEvent('Buy - Admission dates - Completed',
      getEventProperties({
        facilityId,
        facilityName,
        industry,
        affiliationHash,
        activityId,
        activityName,
        chosenVariants,
        language,
        admissionDateDay,
        numberOfSelectedDates,
        admissionDateHour,
        result,
        error,
        abTest
      })
    );
  });

  pubsub.on(EVENT_BUY_SEES_SCREEN_WITH_PAYMENT_LINK_GENERATED, ({
    facilityId,
    facilityName,
    industry,
    orderId
  }) => {
    amplitude.logEvent('Buy - Sees screen with payment link generated',
      getEventProperties({
        facilityId,
        facilityName,
        industry,
        orderId
      })
    );
  });

  pubsub.on(EVENT_BUY_CLICK_ON_RETRY_PAYMENT, ({
    facilityId,
    facilityName,
    industry,
    affiliationHash,
    isFirstLoading,
    orderId,
    language
  }) => {
    amplitude.logEvent('Buy - Clicks on retry payment',
      getEventProperties({
        facilityId,
        facilityName,
        industry,
        affiliationHash,
        isFirstLoading,
        orderId,
        language
      })
    );
  });

  pubsub.on(EVENT_SHOW_VARIANTS_LIST, ({
    facilityId,
    facilityName,
    industry,
    affiliationHash,
    activityId,
    activityName,
    passType,
    orderNumber,
    language,
    widgetMode,
    abTest
  }) => {
    amplitude.logEvent('View activities - Expands variants',
      getEventProperties({
        facilityId,
        facilityName,
        industry,
        affiliationHash,
        activityId,
        activityName,
        passType,
        orderNumber,
        language,
        widgetMode,
        abTest
      })
    );
  });

  pubsub.on(EVENT_HIDE_VARIANTS_LIST, ({
    facilityId,
    facilityName,
    industry,
    affiliationHash,
    activityId,
    activityName,
    passType,
    orderNumber,
    language,
    widgetMode,
    abTest
  }) => {
    amplitude.logEvent('View activities - Hide variants',
      getEventProperties({
        facilityId,
        facilityName,
        industry,
        affiliationHash,
        activityId,
        activityName,
        passType,
        orderNumber,
        language,
        widgetMode,
        abTest
      })
    );
  });

  pubsub.on(EVENT_INVOICE_FORM_ENABLED, ({
    facilityId,
    facilityName
  }) => {
    amplitude.logEvent('Buy - Invoice data - Form enabled',
      getEventProperties({
        facilityId,
        facilityName
      })
    );
  });

  pubsub.on(EVENT_INVOICE_FORM_DISABLED, ({
    facilityId,
    facilityName
  }) => {
    amplitude.logEvent('Buy - Invoice data - Form disabled',
      getEventProperties({
        facilityId,
        facilityName
      })
    );
  });

  // when a user filled company NIP
  pubsub.on(EVENT_INVOICE_FORM_NIP_FILLED, ({
    facilityId,
    facilityName,
    country,
    nip
  }) => {
    amplitude.logEvent('Buy - Invoice data - Company NIP filled',
      getEventProperties({
        facilityId,
        facilityName,
        country,
        nip
      })
    );
  });

  // when we fill at least one invoice data form field
  pubsub.on(EVENT_INVOICE_FORM_COMPANY_DATA_LOAD, ({
    facilityId,
    facilityName,
    nip
  }) => {
    amplitude.logEvent('Buy - Invoice data - Company data loaded',
      getEventProperties({
        facilityId,
        facilityName,
        nip
      })
    );
  });

};
