import React from 'react';
import HighlightedSection from './HighlightedSection';
import locale from '@Utils/locale';
import Typography from '@mui/material/Typography';

const PaymentCardDescription = () => (
  <HighlightedSection>
    <Typography variant="body2">
      {locale.translate('paymentCardDescription')}
    </Typography>
  </HighlightedSection>
);

export default PaymentCardDescription;
