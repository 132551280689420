import React from 'react';
import SavingsOutlinedIcon from '@mui/icons-material/SavingsOutlined';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import locale from '@Utils/locale';
import {replaceByElement} from '@Utils/text';
import {getFormattedPrice} from '@Utils/format';
import {ECurrency} from '@Consts/apiGlobals';

type Props = {
  savings: number | null;
  currency: ECurrency;
}

const Savings = ({savings, currency}: Props) => {
  const formattedSavings = `${getFormattedPrice(savings)} ${locale.translate(`${currency}`)}`;

  const createdCopy = replaceByElement(locale.translate('buyNowOnlineAndPayLess'),
    <Typography key={formattedSavings} variant="body2" component="span" sx={{fontWeight: 700, fontSize: '14px'}}>
      {formattedSavings} {locale.translate('less')}
    </Typography>
  );

  return (
    <Alert
      icon={<SavingsOutlinedIcon sx={{fontSize: '22px'}} />}
      severity="success"
    >
      <Typography
        variant="body2"
        sx={{fontSize: '14px'}}
      >
        {createdCopy}
      </Typography>
    </Alert>
  );
};

export default Savings;
