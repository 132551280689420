import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import React from 'react';

type Props = {
    isUpsell: boolean,
    name: string,
    description: string | null
}

const ActivityVariantName = ({isUpsell, name, description}: Props) => (
  <Stack
    spacing={0.5}
    sx={{
      alignSelf: isUpsell ? 'start' : {xs: 'start', md: 'center'},
      mb: isUpsell ? 1 : {xs: 1, md: 0},
      maxWidth: 530,
      mr: {xs: 0, md: 3}
    }}
  >
    <Typography sx={{fontSize: '16px'}}>
      {name}
    </Typography>
    {
      !!description && (
        <Typography sx={{color: 'text.secondary', fontSize: '12px'}} variant="caption">
          {description}
        </Typography>
      )
    }
  </Stack>
);

export default ActivityVariantName;
