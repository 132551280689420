import React, {useEffect, useState} from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import PropTypes from 'prop-types';
import {updateBasketItemsSelections} from '@Features/basket/basketActions';
import {useDispatch, useSelector} from 'react-redux';
import locale from '@Utils/locale';
import Counter from '@Components/common/counter/Counter';
import FlexBox from '@Components/common/flexBox/FlexBox';
import Text from '@Components/common/text/Text';
import Header from '@Components/common/header/Header';
import classNames from 'classnames';
import {getBasketItemByUid} from '@Features/basket/basketSelectors';
import {ETextColor} from '@Components/common/text/types';
import {EFlexDirection} from '@Components/common/flexBox/types';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Button from '@mui/material/Button';

const ParticipantsForm = ({
  activeView,
  onChangeView,
  description,
  fullCheckoutProgress,
  onPageClose,
  requestedCheckoutPageId
}) => {
  const dispatch = useDispatch();
  const {items, id} = activeView;
  const basketItem = items[0];
  const {activity, uid} = basketItem;
  const basketItemWithSelections = useSelector(state => getBasketItemByUid(state, uid));
  const {selections} = basketItemWithSelections;
  const {numberOfParticipants, time} = selections;
  const {admissionPolicy} = activity;
  const {minNumberOfParticipants, maxNumberOfParticipants} = admissionPolicy;
  const [error, setError] = useState(null);

  useEffect(() => {
    if (requestedCheckoutPageId && time !== null) {
      return onChangeView(requestedCheckoutPageId);
    }
  }, [requestedCheckoutPageId]);

  function handleOnClosePage() {
    const isPageCloseRequest = true;

    return handleClickNext({isPageCloseRequest});
  }

  function handleClickNext({isPageCloseRequest}) {
    if (!numberOfParticipants) {
      return setError(locale.translate('requiredField'));
    }

    if (numberOfParticipants < minNumberOfParticipants) {
      return setError(locale.translate('minNumberOfParticipants', {number: minNumberOfParticipants}));
    }

    if (numberOfParticipants > maxNumberOfParticipants) {
      return setError(locale.translate('maxNumberOfParticipants', {number: maxNumberOfParticipants}));
    }

    if (isPageCloseRequest && time !== null) {
      return onPageClose();
    }

    return onChangeView(id + 1);
  }

  function handleChange(value) {
    setError(null);

    return dispatch(updateBasketItemsSelections(
      items,
      {numberOfParticipants: value, dates: null, time: null}
    ));
  }

  function handleFocus() {
    return setError(null);
  }

  function handleBlur(value) {
    handleChange(value);

    if (value === null) {
      return setError(locale.translate('requiredField'));
    }

    if (value < minNumberOfParticipants) {
      return setError(locale.translate('minNumberOfParticipants', {number: minNumberOfParticipants}));
    }

    if (value > maxNumberOfParticipants) {
      return setError(locale.translate('maxNumberOfParticipants', {number: maxNumberOfParticipants}));
    }

  }

  return (
    <FlexBox direction={EFlexDirection.COLUMN} className="dl-dotpay dl-dotpay__checkout-page">
      <div className="dl-mb-3 dl-dotpay__content--with-icon">
        <Text color={ETextColor.LIGHT}>
          {locale.translate('forChosen')} {description}
        </Text>
        <div
          className={classNames(
            {
              'dl-dotpay--clickable dl-icon dl-icon--margin-correction dl-icon--arrow-up': fullCheckoutProgress
            })
          }
          onClick={handleOnClosePage}
        />
      </div>

      <div className="dl-mb-3">
        <Header>
          {locale.translate('enterNumberOfParticipants')}
        </Header>
      </div>

      <FlexBox className="dl-mb-3">
        <Counter
          name="numberOfParticipants"
          min={minNumberOfParticipants}
          max={maxNumberOfParticipants}
          value={numberOfParticipants}
          onFocus={handleFocus}
          onChange={handleChange}
          onBlur={handleBlur}
          invalid={error}
        />
      </FlexBox>

      {
        error &&
          <Text color={ETextColor.ERROR}>{error}</Text>
      }
      <Button
        onClick={handleClickNext}
        variant="contained"
        color="success"
        fullWidth
        sx={{mt: 2}}
        endIcon={<ChevronRightIcon />}
      >
        {locale.translate('next')}
      </Button>
    </FlexBox>
  );
};

ParticipantsForm.propTypes = {
  activeView: PropTypes.object.isRequired,
  onChangeView: PropTypes.func.isRequired,
  description: PropTypes.string.isRequired
};

export default ParticipantsForm;
