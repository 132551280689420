import {InternalPaymentMethods, OnlinePaymentMethods} from '@Components/checkout/types';
import {IShippingMethod, PaymentType} from '@Consts/apiGlobals';
import {EWidgetType} from '@Consts/globals';
import {IBasketItem} from '@Consts/types';
import {getBasketValue} from './basket';
import {getBasketItemPrice} from './basketItemPrice';
import {getFormattedPrice} from './format';
import locale from './locale';
import {isWidgetBusiness, isWidgetInternal, isWidgetInternalPreview, isWidgetPreview} from './widgetType';

type PriceToPay = {
  amount: string | null,
  currency: string | null
};

export const getCheckoutPriceToPay = (
  basketItems: IBasketItem[],
  paymentType?: PaymentType | null,
  shippingMethod?: IShippingMethod | null
) => {
  const basketValue = getBasketValue(basketItems, true);
  const basketItemPrice = getBasketItemPrice(basketItems[0], false);
  const basketCurrency = basketItemPrice.currency;
  const withoutAmount = !basketValue || paymentType === 'for_used';
  const shippingPrice = shippingMethod?.price?.amount || 0;

  if ((!withoutAmount || shippingPrice) && basketCurrency) {
    return {
      amount: getFormattedPrice(basketValue + shippingPrice),
      currency: basketCurrency
    };
  }

  return {amount: null, currency: null};
};

export const getCheckoutButtonLabel = (
  priceToPay: PriceToPay,
  widgetType: EWidgetType,
  paymentType?: PaymentType | null,
  paymentMethod?: OnlinePaymentMethods | InternalPaymentMethods
) => {
  const {amount, currency} = priceToPay;
  const formattedPriceToPay = amount ? `${amount} ${locale.translate(currency)}` : '';

  if (paymentMethod === OnlinePaymentMethods.BLIK && amount) {
    return `${locale.translate('paymentWithBlik')} (${formattedPriceToPay})`;
  }

  const defaultText = amount ?
    `${locale.translate('goToThePayment')} (${formattedPriceToPay})` :
    locale.translate('realiseOrder');

  if (isWidgetInternal(widgetType) || isWidgetInternalPreview(widgetType)) {
    return amount ?
      locale.translate('generateWithAmount', {amountToPay: formattedPriceToPay}) :
      locale.translate('generate');
  }

  if (isWidgetPreview(widgetType)) {
    return locale.translate('generateExampleOrder');
  }

  if (isWidgetBusiness(widgetType)) {
    if (paymentType === 'delayed') {
      const text = locale.translate('buyWithoutPayment');

      return amount ? `${text} (${formattedPriceToPay})` : text;
    }

    if (paymentType === 'for_used') {
      return locale.translate('doOrder');
    }

    if (paymentType === 'on_site') {
      return locale.translate('doReservation');
    }

    return defaultText;
  }

  return defaultText;
};
