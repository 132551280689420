import React, {useEffect, useState} from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import QuestionFormContent from './QuestionFormContent';
import {getFacility} from '@Features/facility/facilitySelectors';
import {getAffiliationHash} from '@Features/configuration/configurationSelectors';
import pubsub from '@Utils/pubsub';
import {EVENT_QUESTION_COMPLETE, EVENT_VIEW_QUESTION} from '@Utils/events';
import locale from '@Utils/locale';
import {useDispatch, useSelector} from 'react-redux';
import {updateBasketItemsSelections} from '@Features/basket/basketActions';
import {isAnswerValid} from '@Utils/validate';
import {getAnswerFormatted} from '@Utils/format';
import {parseLanguage} from '@Utils/eventsUtils';
import {EAnswerScope} from '@Consts/apiGlobals';

const QuestionForm = ({
  activeView,
  description,
  onChangeView,
  onPageClose,
  fullCheckoutProgress,
  requestedCheckoutPageId
}) => {
  const dispatch = useDispatch(null);
  const facility = useSelector(getFacility);
  const affiliationHash = useSelector(getAffiliationHash);
  const {items, id, questionId} = activeView;
  const basketItem = items[0];
  const {variant, selections} = basketItem;
  const {answers} = selections;
  const {questions} = variant;
  const question = questions && questions?.length ? questions.find(question => question.id === questionId) : null;
  const initialAnswer = answers && answers.length ? answers.find(answer => answer.questionId === questionId) : null;
  const [answer, setAnswer] = useState(initialAnswer?.answer || null);

  const [invalid, setInvalid] = useState(false);
  const pubsubProperties = {
    facilityId: facility?.id,
    facilityName: facility?.name,
    industry: facility?.company?.industry,
    affiliationHash,
    language: parseLanguage(locale.locale)
  };

  if (question) {
    const {text, type, isRequired} = question;

    pubsubProperties.text = text;
    pubsubProperties.type = type;
    pubsubProperties.isRequired = isRequired;
  }

  useEffect(() => {
    if (requestedCheckoutPageId) {
      onChangeView(requestedCheckoutPageId);
    }
  }, [requestedCheckoutPageId]);

  useEffect(() => {
    const questionAnwser = answers.find(answer => answer.questionId === questionId);
    const questionAnwserText = questionAnwser ? questionAnwser.answer : null;

    setAnswer(questionAnwserText);
  }, [id]);

  useEffect(() => {
    if (question) {
      pubsub.trigger(EVENT_VIEW_QUESTION, pubsubProperties);
    }
  }, []);

  function logEvent(data) {
    pubsub.trigger(EVENT_QUESTION_COMPLETE, {...pubsubProperties, ...data});
  }

  function handleOnClosePage() {
    const isPageCloseRequest = true;

    return handleClickNext({isPageCloseRequest});
  }

  function handleClickNext({isPageCloseRequest = false, requestedCheckoutPageId = null}) {
    const answerFormatted = getAnswerFormatted(answer, question);

    if (isAnswerValid(answerFormatted, question)) {
      setAnswer(null);
      logEvent({result: 'ok', answer: answerFormatted});
      dispatch(updateBasketItemsSelections(
        items,
        {answers: [
          {
            questionId,
            answer: answerFormatted,
            isActivityAnswerScope: question.answerScope === EAnswerScope.ACTIVITY
          }
        ]}
      ));

      if (isPageCloseRequest) {
        return onPageClose();
      }

      if (requestedCheckoutPageId) {
        return onChangeView(requestedCheckoutPageId);
      }

      return onChangeView(id + 1);
    }

    setAnswer(answerFormatted);
    logEvent({result: 'error', error: locale.translate('requiredField')});
    return setInvalid(true);
  }

  if (question) {
    return (
      <div className="dl-dotpay dl-dotpay__checkout-page">
        <QuestionFormContent
          question={question}
          value={answer}
          invalid={invalid}
          description={description}
          setInvalid={setInvalid}
          setAnswer={setAnswer}
          onClickNext={handleClickNext}
          viewId={id}
          fullCheckoutProgress={fullCheckoutProgress}
          onPageClose={handleOnClosePage}
        />
      </div>
    );
  }

  return null;
};

export default QuestionForm;
