import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import Text from '@Components/common/text/Text';
import {ETextTransform} from '@Components/common/text/types';

export const PLACEHOLDER = '%p';

export const replaceByElement = (text: string, element: string | React.ReactElement) => {
  const splittedText = text.split(PLACEHOLDER);

  return splittedText.reduce<(string | React.ReactElement)[]>((accumulator, fragment, fragmentIndex) => {
    accumulator.push(fragment);

    if (fragmentIndex !== splittedText.length - 1) {
      accumulator.push(element);
    }

    return accumulator;
  }, []);
};

export const getTextWithExtractedLinks = (text: string) => {
  const splittedComment = text.split('[');

  if (splittedComment.length > 1) {
    return splittedComment.reduce<(React.ReactElement)[]>((allChunks, commentChunk) => {
      const splittedChunk = commentChunk.split(']');

      if (splittedChunk.length > 1) {
        const [linkText, rawHref] = splittedChunk;
        const [prevHref, restChunk] = rawHref.split(')');
        const href = prevHref.split('');

        href.splice(0, 1);

        const link = href.join('');

        allChunks.push(
          <a href={link} target="_blank" rel="noopener noreferrer" key={link}>
            <Text transform={ETextTransform.UNDERLINE}>{linkText}</Text>
          </a>
        );
        allChunks.push(<span key={restChunk}>{restChunk}</span>);
      } else {
        allChunks.push(<span key={commentChunk}>{commentChunk}</span>);
      }

      return allChunks;
    }, []);
  }

  return text;
};
