import {EPassType} from '@Consts/apiGlobals';
import {IBasketItemSelections} from '@Consts/types';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import {isActivitySinglePickDate, isActivityWithDateTimes} from '@Utils/activity/activity';
import locale from '@Utils/locale';
import React from 'react';
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import AdmissionSummaryDetails from './AdmissionSummaryDetails';
import {getSummaryDetails} from '../utils';
import MarkedHeader from '@Components/headers/MarkedHeader';
import DatesRangeDetails from './DatesRangeDetails';
import PriceDetails from './PriceDetails';
import {useFeatureFlag, Features} from '@Hooks/useFeatureFlag';
import Savings from './Savings';

type Props = {
    selections: IBasketItemSelections
    passType: EPassType
}

const AdmissionSummary = ({selections, passType}: Props) => {
  const {time: selectedTime, dates: selectedDates} = selections;
  const selectedTimeDescription = selectedTime?.description;
  const isSinglePickDate = isActivitySinglePickDate(passType);
  const isDateTimes = isActivityWithDateTimes(passType);
  const selectedDatesDescription = isSinglePickDate &&
  selectedDates?.[0]?.group[0]?.description;
  const isDatesRange = isSinglePickDate && !!selections?.dates?.length && selections?.dates.length > 1;
  const summaryDetails = getSummaryDetails(isDateTimes, isDatesRange, selections);
  const {isEnabled} = useFeatureFlag(Features.TEST_B);

  if (!summaryDetails) {
    return null;
  }

  const {
    price: totalPrice,
    offlinePrice,
    currency,
    date,
    firstDate,
    lastDate
  } = summaryDetails;

  const savings = offlinePrice ? offlinePrice - totalPrice : null;

  return (
    <Box sx={{mb: 2}}>
      <Box sx={{mt: 3}}>
        <MarkedHeader text={locale.translate('selected')} />
      </Box>
      <Stack spacing={1} sx={{color: 'text.primary'}}>
        <PriceDetails
          totalPrice={totalPrice}
          offlinePrice={offlinePrice}
          currency={currency}
        />
        {isEnabled && !!savings && (
          <Savings savings={savings} currency={currency} />
        )}
        {isDatesRange ? (
          <DatesRangeDetails firstDate={firstDate!} lastDate={lastDate!} />
        ) : (
          <AdmissionSummaryDetails
            Icon={EventAvailableOutlinedIcon}
            boldText={date}
            text={`${locale.translate('term')}: `}
          />
        )}
        {
          (!!selectedTimeDescription || !!selectedDatesDescription) && (
            <Alert severity="info" sx={{mb: 1}}>
              {selectedTimeDescription || selectedDatesDescription}
            </Alert>
          )
        }
      </Stack>
    </Box>
  );
};

export default AdmissionSummary;
