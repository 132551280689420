import locale from '@Utils/locale';
import React from 'react';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import AdmissionSummaryDetails from './AdmissionSummaryDetails';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {Divider, Theme, useMediaQuery} from '@mui/material';
import {getFormattedPrice} from '@Utils/format';
import {ECurrency} from '@Consts/apiGlobals';
import {useFeatureFlag, Features} from '@Hooks/useFeatureFlag';

type Props = {
  totalPrice: number;
  offlinePrice: number | null;
  currency: ECurrency;
}

const PriceDetails = ({totalPrice, offlinePrice, currency}: Props) => {
  const {isEnabled} = useFeatureFlag(Features.TEST_B);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const formattedPrice = `${getFormattedPrice(totalPrice)} ${locale.translate(`${currency}`)}`;
  const formattedOfflinePrice = offlinePrice ?
      `${getFormattedPrice(offlinePrice)} ${locale.translate(`${currency}`)}` : null;
  const canShowOfflinePrice = !!(offlinePrice && offlinePrice > totalPrice);

  if (isEnabled && canShowOfflinePrice) {
    return (
      <AdmissionSummaryDetails Icon={PaidOutlinedIcon}>
        <Stack
          direction={{xs: 'column', sm: 'row'}}
          spacing={{xs: 0.5, sm: 2}}
          sx={{py: 1}}
          divider={isMobile ? null : <Divider orientation="vertical" flexItem />}
        >
          <Typography
            variant="body2"
            sx={{
              alignContent: 'center',
              fontSize: '14px'
            }}
          >
            {`${locale.translate('onlinePrice')}: `}
            <Typography variant="body2" component="span" sx={{fontWeight: 700, fontSize: '14px'}}>
              {formattedPrice}
            </Typography>
          </Typography>
          <Typography
            variant="caption"
            component="p"
            sx={{
              alignContent: 'center',
              fontSize: '12px',
              color: 'text.secondary'
            }}
          >
            {`${locale.translate('priceAtTicketOffice')}: ${formattedOfflinePrice}`}
          </Typography>
        </Stack>
      </AdmissionSummaryDetails>
    );
  }

  return (
    <AdmissionSummaryDetails
      Icon={PaidOutlinedIcon}
      boldText={formattedPrice}
      text={`${locale.translate('price')}: `}
    />
  );
};

export default PriceDetails;
