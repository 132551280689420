import {clearEcommerce, getSelectionFormatAdmissionDate, getSelectionsVariantPrice} from './utils';
import {IFacility} from '@Consts/apiGlobals';
import directFrameMessage from '@Utils/directFrameMessage';
import {EGtmEvents, GTM_MESSAGE_NAME} from './core';
import {IBasketItem} from '@Consts/types';
import {AgreementsOrderResponse} from '@Components/checkoutPages/agreements/types';

type EcommerceItem = {
  item_id: number,
  item_name: string,
  item_list_id: number | null,
  item_variant: string | null,
  quantity: number,
  price: number | null,
  admission_date: string | null
};

type BasketData = {
  basketItems: IBasketItem[],
  price: number,
  currency: string | null
}

const getEcommerceItems = (
  basketItems: IBasketItem[],
  onlineGroupId: number | null,
  timezone: string
) => {
  const items = basketItems.reduce((
    ecommerceItems: {[key: number]: EcommerceItem},
    basketItem
  ) => {
    const {activity, variant, selections, variantId} = basketItem;
    const {discount, numberOfParticipants, numberOfSeats} = selections;
    const singleItemPrice = discount ? discount.price :
      getSelectionsVariantPrice(variantId, selections) ?? variant.cost?.price!;
    const prevQuantity = ecommerceItems?.[variant.id]?.quantity ?? 0;
    const quantity = numberOfParticipants ?? numberOfSeats ?? 1;
    const admissionDate = getSelectionFormatAdmissionDate(selections, timezone);

    ecommerceItems[variant.id] = {
      item_id: activity.id,
      item_name: activity.name,
      item_list_id: onlineGroupId,
      item_variant: variant.name,
      quantity: prevQuantity + quantity,
      price: singleItemPrice,
      admission_date: admissionDate
    };

    return ecommerceItems;
  }, {});

  return Object.values(items ?? {});
};

const beginPayment = (
  facility: IFacility,
  onlineGroupId: number | null,
  order: AgreementsOrderResponse,
  basketItems: IBasketItem[]
) => {
  const {isGoogleTagManagerEnabled, timezone} = facility;

  if (!isGoogleTagManagerEnabled) {
    return;
  }

  const {tax, price, id, currency} = order;

  clearEcommerce();
  directFrameMessage({
    name: GTM_MESSAGE_NAME,
    gtmData: {
      event: EGtmEvents.GTM_TAG_BEGIN_PAYMENT,
      ecommerce: {
        transaction_id: id,
        currency,
        value: price,
        tax,
        items: getEcommerceItems(basketItems, onlineGroupId, timezone)
      }
    }
  });
};

const beginCheckout = (
  facility: IFacility,
  onlineGroupId: number | null,
  basketData: BasketData
) => {
  const {isGoogleTagManagerEnabled, timezone} = facility;

  if (!isGoogleTagManagerEnabled) {
    return;
  }

  const {basketItems, price, currency} = basketData;

  clearEcommerce();
  directFrameMessage({
    name: GTM_MESSAGE_NAME,
    gtmData: {
      event: EGtmEvents.GTM_TAG_BEGIN_CHECKOUT,
      ecommerce: {
        currency,
        value: price,
        items: getEcommerceItems(basketItems, onlineGroupId, timezone)
      }
    }
  });
};

export default {
  beginPayment,
  beginCheckout
};
