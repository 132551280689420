import {ECurrency} from '@Consts/apiGlobals';
import Typography from '@mui/material/Typography';
import {getVariantPriceWithCurrency} from '@Utils/activity/variantUtils';
import locale from '@Utils/locale';
import React from 'react';

type Props = {
    catalogPrice: number,
    currency: ECurrency,
    withDifferentPrices: boolean
}

const CatalogPrice = ({catalogPrice, currency, withDifferentPrices}: Props) => {
  const formattedPrice = `${getVariantPriceWithCurrency({price: catalogPrice, currency}).priceText}`;

  return (
    <Typography
      sx={{
        fontSize: '16px',
        mr: 0.5,
        color: '#C75A67',
        textDecoration: 'line-through',
        whiteSpace: 'nowrap'
      }}
    >
      {withDifferentPrices && (
        <>
          {locale.translate('from').toLowerCase()}&nbsp;
        </>
      )}
      {formattedPrice}
    </Typography>
  );
};

export default CatalogPrice;
