import {object, string, boolean} from 'yup';
import locale from '@Utils/locale';
import {schema as phonePrefixSchema} from '@Components/checkout/field/PhonePrefixField';
import {schema as phoneNumberSchema} from '@Components/checkout/field/PhoneNumberField';
import {schema as firstNameSchema} from '@Components/checkout/field/FirstNameField';
import {schema as lastNameSchema} from '@Components/checkout/field/LastNameField';
import {schema as emailSchema} from '@Components/checkout/field/EmailField';
import {schema as termsOfServiceSchema} from '../../formFields/TermsOfServiceField';
import {schema as companyNameFieldSchema} from '@Components/invoice/formFields/CompanyNameField';
import {schema as companyNipFieldSchema} from '@Components/invoice/formFields/CompanyNipField';
import {schema as companyVatIdFieldSchema} from '@Components/invoice/formFields/CompanyVatIdField';
import {schema as invoiceFirstNameSchema} from '@Components/invoice/formFields/InvoiceFirstNameField';
import {schema as invoiceLastNameFieldSchema} from '@Components/invoice/formFields/InvoiceLastNameField';
import {schema as invoiceCityFieldSchema} from '@Components/invoice/formFields/InvoiceCityField';
import {schema as invoiceStreetFieldSchema} from '@Components/invoice/formFields/InvoiceStreetField';
import {schema as invoicePostalCodeFieldSchema} from '@Components/invoice/formFields/InvoicePostalCodeField';
import {
  schema as invoicePostalCodeSimpleFieldSchema
} from '@Components/invoice/formFields/InvoicePostalCodeSimpleField';
import {schema as invoiceCountryFieldSchema} from '@Components/invoice/formFields/InvoiceCountryField';
import {schema as invoiceNoteFieldSchema} from '@Components/invoice/formFields/InvoiceNoteField';
import {CountryType, InvoiceAddressCountry} from '@Consts/invoiceCountries';
import {EInvoiceFormType} from '@Components/invoice/types';
import {schema as paymentMethodSchema} from './paymentMethod/formFields/PaymentMethodPicker';
import {OnlinePaymentMethods} from '@Components/checkout/types';
import {schema as blikCodeFieldSchema} from '@Components/checkoutPages/agreements/paymentMethod/formFields/BlikField';
import {EnabledPaymentMethods} from '@Hooks/usePaymentMethodsEnabled';

export const getAgreementsValidationSchema = (isMultipleTerms: boolean) => object({
  email: string().when('$widgetIsBusiness', {
    is: false,
    then: () => emailSchema().required(locale.translate('requiredField'))
  }),
  firstName: string().when('$widgetIsBusiness', {
    is: false,
    then: () => firstNameSchema().required(locale.translate('requiredField'))
  }),
  lastName: string().when('$widgetIsBusiness', {
    is: false,
    then: () => lastNameSchema().required(locale.translate('requiredField'))
  }),
  selectAllConsets: boolean(),
  termsOfService: boolean().when('$widgetIsBusiness', {
    is: false,
    then: () => termsOfServiceSchema(isMultipleTerms)
  }),
  isAgreeMarketingConsents: boolean(),
  phoneNumber: string().when('$withContactPhoneNumber', {
    is: true,
    then: () => phoneNumberSchema()
  }),
  phonePrefix: object().when('$withContactPhoneNumber', {
    is: true,
    then: () => phonePrefixSchema()
  }),
  paymentMethod: string().when('$enabledPaymentMethods', {
    is: (enabledPaymentMethods: EnabledPaymentMethods) =>
      enabledPaymentMethods.isSecureFormBlikEnabled ||
      enabledPaymentMethods.isCardEnabled,
    then: () => paymentMethodSchema()
  }),
  blikCode: string().when(['$enabledPaymentMethods', 'paymentMethod'], {
    is: (enabledPaymentMethods: EnabledPaymentMethods, paymentMethod: OnlinePaymentMethods) =>
      enabledPaymentMethods.isSecureFormBlikEnabled && paymentMethod === OnlinePaymentMethods.BLIK,
    then: () => blikCodeFieldSchema()
  }),
  sendInvoice: boolean(),
  invoiceFormType: string().oneOf(Object.values(EInvoiceFormType)),
  invoiceCompanyName: string().when(['sendInvoice', 'invoiceFormType', '$isSimplifiedInvoiceForm'], {
    is: (sendInvoice: boolean, invoiceFormType: EInvoiceFormType, isSimplifiedInvoiceForm: boolean) =>
      sendInvoice && invoiceFormType === EInvoiceFormType.COMPANY && !isSimplifiedInvoiceForm,
    then: () => companyNameFieldSchema()
  }),
  invoiceCompanyNip: string().when(['sendInvoice', 'invoiceFormType', 'invoiceAddressCountry'],
    keys => {
      const [sendInvoice, invoiceFormType, invoiceAddressCountry] = keys as
    [boolean, EInvoiceFormType, CountryType];

      if (sendInvoice && invoiceFormType === EInvoiceFormType.COMPANY) {
        return invoiceAddressCountry.code === InvoiceAddressCountry.PL ?
          companyNipFieldSchema() : companyVatIdFieldSchema();
      }

      return string().notRequired();
    }),
  invoiceFirstName: string().when(['sendInvoice', 'invoiceFormType'], {
    is: (sendInvoice: boolean, invoiceFormType: EInvoiceFormType) =>
      sendInvoice && invoiceFormType === EInvoiceFormType.PRIVATE_PERSON,
    then: () => invoiceFirstNameSchema()
  }),
  invoiceLastName: string().when(['sendInvoice', 'invoiceFormType'], {
    is: (sendInvoice: boolean, invoiceFormType: EInvoiceFormType) =>
      sendInvoice && invoiceFormType === EInvoiceFormType.PRIVATE_PERSON,
    then: () => invoiceLastNameFieldSchema()
  }),
  invoiceAddressCity: string().when(['sendInvoice', '$isSimplifiedInvoiceForm', 'invoiceFormType'], {
    is: (sendInvoice: boolean, isSimplifiedInvoiceForm: boolean, invoiceFormType: EInvoiceFormType) =>
      sendInvoice && !(isSimplifiedInvoiceForm && invoiceFormType === EInvoiceFormType.COMPANY),
    then: () => invoiceCityFieldSchema()
  }),
  invoiceAddressStreet: string().when(['sendInvoice', '$isSimplifiedInvoiceForm', 'invoiceFormType'], {
    is: (sendInvoice: boolean, isSimplifiedInvoiceForm: boolean, invoiceFormType: EInvoiceFormType) =>
      sendInvoice && !(isSimplifiedInvoiceForm && invoiceFormType === EInvoiceFormType.COMPANY),
    then: () => invoiceStreetFieldSchema()
  }),
  invoiceAddressPostalCode: string().when([
    'sendInvoice',
    '$isSimplifiedInvoiceForm',
    'invoiceFormType',
    'invoiceAddressCountry'
  ],
  keys => {
    const [sendInvoice, isSimplifiedInvoiceForm, invoiceFormType, invoiceAddressCountry] = keys as
      [boolean, boolean, EInvoiceFormType, CountryType];

    if (sendInvoice && !(isSimplifiedInvoiceForm && invoiceFormType === EInvoiceFormType.COMPANY)) {
      return invoiceAddressCountry.code === InvoiceAddressCountry.PL ?
        invoicePostalCodeFieldSchema() : invoicePostalCodeSimpleFieldSchema();
    }

    return string().notRequired();
  }),
  invoiceAddressCountry: object().when(['sendInvoice', '$isSimplifiedInvoiceForm', 'invoiceFormType'], {
    is: (sendInvoice: boolean, isSimplifiedInvoiceForm: boolean, invoiceFormType: EInvoiceFormType) =>
      sendInvoice && !(isSimplifiedInvoiceForm && invoiceFormType === EInvoiceFormType.COMPANY),
    then: () => invoiceCountryFieldSchema()
  }),
  invoiceNote: string().when(['sendInvoice', '$isUsersInvoiceNoteEnabled'], {
    is: (sendInvoice: boolean, isUsersInvoiceNoteEnabled: boolean) => sendInvoice && isUsersInvoiceNoteEnabled,
    then: () => invoiceNoteFieldSchema()
  }),
  isInvoiceRecipient: boolean(),
  invoiceRecipientName: string().when(
    ['sendInvoice', 'isInvoiceRecipient', '$isSimplifiedInvoiceForm', 'invoiceFormType'],
    {
      is: (
        sendInvoice: boolean,
        isInvoiceRecipient: boolean,
        isSimplifiedInvoiceForm: boolean,
        invoiceFormType: EInvoiceFormType) =>
        sendInvoice && isInvoiceRecipient && !isSimplifiedInvoiceForm && invoiceFormType === EInvoiceFormType.COMPANY,
      then: () => companyNameFieldSchema()
    }),
  invoiceRecipientAddressStreet: string().when(
    ['sendInvoice', 'isInvoiceRecipient', '$isSimplifiedInvoiceForm', 'invoiceFormType'],
    {
      is: (
        sendInvoice: boolean,
        isInvoiceRecipient: boolean,
        isSimplifiedInvoiceForm: boolean,
        invoiceFormType: EInvoiceFormType) =>
        sendInvoice && isInvoiceRecipient && !isSimplifiedInvoiceForm && invoiceFormType === EInvoiceFormType.COMPANY,
      then: () => invoiceStreetFieldSchema()
    }),
  invoiceRecipientAddressCity: string().when(
    ['sendInvoice', 'isInvoiceRecipient', '$isSimplifiedInvoiceForm', 'invoiceFormType'],
    {
      is: (
        sendInvoice: boolean,
        isInvoiceRecipient: boolean,
        isSimplifiedInvoiceForm: boolean,
        invoiceFormType: EInvoiceFormType) =>
        sendInvoice && isInvoiceRecipient && !isSimplifiedInvoiceForm && invoiceFormType === EInvoiceFormType.COMPANY,
      then: () => invoiceCityFieldSchema()
    }),
  invoiceRecipientAddressPostalCode: string().when([
    'sendInvoice',
    'isInvoiceRecipient',
    'invoiceRecipientAddressCountry',
    'invoiceFormType',
    '$isSimplifiedInvoiceForm'
  ],
  keys => {
    const [
      sendInvoice,
      isInvoiceRecipient,
      invoiceRecipientAddressCountry,
      invoiceFormType,
      isSimplifiedInvoiceForm
    ] = keys as [boolean, boolean, CountryType, EInvoiceFormType, boolean];

    if (sendInvoice && isInvoiceRecipient && invoiceFormType === EInvoiceFormType.COMPANY && !isSimplifiedInvoiceForm) {
      return invoiceRecipientAddressCountry.code === InvoiceAddressCountry.PL ?
        invoicePostalCodeFieldSchema() : invoicePostalCodeSimpleFieldSchema();
    }

    return string().notRequired();
  }),
  invoiceRecipientAddressCountry: object().when(
    ['sendInvoice', 'isInvoiceRecipient', '$isSimplifiedInvoiceForm', 'invoiceFormType'],
    {
      is: (
        sendInvoice: boolean,
        isInvoiceRecipient: boolean,
        isSimplifiedInvoiceForm: boolean,
        invoiceFormType: EInvoiceFormType) =>
        sendInvoice && isInvoiceRecipient && !isSimplifiedInvoiceForm && invoiceFormType === EInvoiceFormType.COMPANY,
      then: () => invoiceCountryFieldSchema()
    })
});
