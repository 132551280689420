import {OnlinePaymentMethods} from '@Components/checkout/types';
import {IRadioInput} from '@Components/formComponents/FormRadioGroup';
import React from 'react';
import SecureFormDescription from '../SecureFormDescription';
import BlikSection from '../BlikSection';
import LabelIcon from '../LabelIcon';
import locale from '@Utils/locale';
import {usePaymentMethodsEnabled} from '@Hooks/usePaymentMethodsEnabled';
import PaymentCardDescription from '../PaymentCardDescription';

export const usePaymentMethodPickerInputs = () => {
  const {isSecureFormBlikEnabled, isCardEnabled, isApplePayEnabled} = usePaymentMethodsEnabled();

  const secureFormBlikRadioInputs: IRadioInput[] = [
    {
      value: OnlinePaymentMethods.SECURE_FORM,
      label: locale.translate('fastTransfer'),
      children: <SecureFormDescription />,
      labelAdornment: <LabelIcon
        src="img/paymentMethods/autopay-logo.svg"
        alt="Autopay logo"
        width={45}
      />
    },
    {
      value: OnlinePaymentMethods.BLIK,
      label: locale.translate('blik'),
      children: <BlikSection />,
      labelAdornment: <LabelIcon
        src="img/paymentMethods/blik-logo.svg"
        alt="Blik logo"
        width={34}
      />
    }
  ];

  const cardsGooglePayRadioInputs: IRadioInput[] = [
    {
      value: OnlinePaymentMethods.PAYMENT_CARD,
      label: locale.translate('paymentCard'),
      children: <PaymentCardDescription />,
      labelAdornment: <LabelIcon
        src="img/paymentMethods/mastercard_visa_marks.svg"
        alt="Mastercard and Visa marks"
        width={31}
      />
    },
    {
      value: OnlinePaymentMethods.GOOGLE_PAY,
      label: locale.translate('googlePay'),
      labelAdornment: <LabelIcon
        src="img/paymentMethods/google_pay_mark.svg"
        alt="Google Pay mark"
        width={41}
      />
    }
  ];

  const applePayRadioInput: IRadioInput[] = [{
    value: OnlinePaymentMethods.APPLE_PAY,
    label: locale.translate('applePay'),
    labelAdornment: <LabelIcon
      src="img/paymentMethods/apple_pay_mark.svg"
      alt="Apple Pay mark"
      width={33}
    />
  }];

  return [
    ...isSecureFormBlikEnabled ? secureFormBlikRadioInputs : [],
    ...isCardEnabled ? cardsGooglePayRadioInputs : [],
    ...isApplePayEnabled ? applePayRadioInput : []
  ];
};
