import React from 'react';
import Stack from '@mui/material/Stack';
import locale from '@Utils/locale';
import AdmissionSummaryDetail from './AdmissionSummaryDetails';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';

type Props = {
    firstDate: string;
    lastDate: string;
}

const DatesRangeDetails = ({firstDate, lastDate}: Props) => (
  <AdmissionSummaryDetail
    Icon={EventAvailableOutlinedIcon}
  >
    <Box sx={{py: 1}}>
      <Typography
        variant="body2"
        sx={{
          mb: 1,
          fontSize: '14px'
        }}
      >
        {`${locale.translate('term')}: `}
      </Typography>
      <Stack
        direction="row"
        spacing={3}
        divider={<Divider orientation="vertical" flexItem />}
        sx={{mt: 1}}
      >
        <Box>
          <Typography variant="body2" sx={{mb: 0.5, fontSize: '14px'}}>
            {locale.translate('startDate')}
          </Typography>
          <Typography variant="body2" sx={{fontSize: '14px', fontWeight: 700}}>
            {firstDate}
          </Typography>
        </Box>
        <Box>
          <Typography variant="body2" sx={{mb: 0.5, fontSize: '14px'}}>
            {locale.translate('endDate')}
          </Typography>
          <Typography variant="body2" sx={{fontSize: '14px', fontWeight: 700}}>
            {lastDate}
          </Typography>
        </Box>
      </Stack>
    </Box>
  </AdmissionSummaryDetail>
);

export default DatesRangeDetails;
