export const EVENT_PROPERTIES = {
  USER_ID: 'User ID',
  FACILITY: 'Facility',
  FACILITY_NAME: 'Facility Name',
  WEBSITE_URL: 'Website URL',
  RESULTS: 'Results',
  WIDGET_LANGUAGE: 'Widget language',
  INDUSTRY: 'Industry',
  AFFILIATE_HASH: 'Affiliate Hash',
  ONLINE_GROUP: 'Online group',
  INVOICE_DATA_SUBMITTED: 'Invoice data submitted',
  TICKETS_COUNT: 'Tickets count',
  FORM_VALUES: 'Form values',
  REQUEST_BODY: 'Request body',
  ORDER_CODE: 'Order code',
  ORDER: 'Order',
  ORDER_STATE: 'Order State',
  ORDER_PAYMENT_URL: 'Order Payment URL',
  ERROR: 'Error',
  FORM_DATA: 'Form data',
  ACTIVITY_ID: 'Activity ID',
  ACTIVITY_NAME: 'Activity Name',
  ERROR_TITLE: 'Error Title',
  ERROR_TYPE: 'Error Type',
  ERROR_STATUS: 'Error Status',
  FETCHING_TIME: 'Fetching Time [ms]',
  ONLINE_STATUS: 'Online Status',
  DATES_SINCE: 'Dates Since',
  DATES_UNTIL: 'Dates Until',
  PASS_TYPE: 'Pass Type',
  PRIORITY_FROM_TOP_TO_BOTTOM: 'Priority from top to bottom',
  LANGUAGE: 'Language',
  ORIGINAL_LANGUAGE: 'Original Language',
  RESULT: 'Result',
  SOURCE: 'Source',
  SALE_TYPE: 'Sale Type',
  MESSAGE: 'Message',
  ADDITIONS: '#Additions',
  QUESTION_TEXT: 'Question text',
  QUESTION_TYPE: 'Question type',
  REQUIRED: 'Required',
  ITEMS: 'Items',
  SHIPPING_METHODS: 'Shipping Methods',
  ANSWER_TEXT: 'Answer text',
  GROUP_ID: 'Group ID',
  URL: 'URL',
  DESTINATION_URL: 'Destination URL',
  DESCRIPTION: 'Description',
  VALIDATION_ERRORS: 'Validation errors',
  RESPONSE_DATA: 'Response data',
  AB_TEST: 'A/B test',
  BASKET_ITEMS: 'Basket items',
  TAG: 'Tag',
  REVENUE_TOTAL: 'Revenue total',
  REVENUE_FROM_UPSELL: 'Revenue from upsell',
  CURRENCY: 'Currency',
  CHOSEN_VARIANTS: 'Chosen variants',
  MISSING_VARIANTS: 'Missing variants',
  SELECTED_SHIPPING_METHOD: 'Selected shipping method',
  MARKETING_CONSENT: 'Marketing consent',
  MARKETING_CONSENT_AVAILABLE: 'Marketing consent available',
  MARKETING_CONSENT_TITLE: 'Marketing consent title',
  MARKETING_CONSENT_DESCRIPTION: 'Marketing consent description',
  IS_FIRST_LOADING: 'First loading',
  FIRST_ADMISSION_DATE: 'First admission date',
  ADMISSION_DATE_DAY: 'Admission date day',
  ADMISSION_DATE_DESCRIPTION: 'Admission date description',
  ADMISSION_DATE_HOUR: 'Admission date hour',
  USER_NEED_TO_CHOOSE_HOUR: 'User need to choose hour',
  NUMBER_OF_AVAILABLE_DAYS: 'Number of available days',
  NUMBER_OF_AVAILABLE_HOURS: 'Number of available hours',
  NUMBER_OF_SELECTED_DATES: 'Number of selected dates',
  MONTH: 'Month',
  WIDGET_MODE: 'Widget mode',
  PAYMENT_METHOD: 'Payment method',
  PAYMENT_METHODS: 'Payment methods',
  REFERRER: 'Referrer',
  UUID: 'UUID',
  CONFIGURATION: 'Configuration',
  DISABLED: 'Disabled',
  BOOKING_WINDOW: 'Booking window',
  COUNTRY: 'Country',
  NIP: 'NIP',
  ADD_TO_BASKET_INPUT_METHOD: 'Method'
};

export const USER_PROPERTIES = {
  USER_ID: 'User ID',
  USER_TYPE: 'User Type',
  DB_COMPANY_USER_ID: 'Db Company User ID',
  DB_COMPANY_ID: 'Db Company ID',
  DB_COMPANY_NAME: 'Db Company Name',
  DB_USER_NAME: 'Db User Name',
  LANGUAGE: 'Language'
};
