import React from 'react';
import {
  getActivityLowestPriceVariant,
  isActivityWithDifferentPrices
} from '@Utils/activity/activity';
import {getConfiguration} from '@Features/configuration/configurationSelectors';
import {useSelector} from 'react-redux';
import {getCatalogPrice} from '@Utils/variant';
import Stack from '@mui/material/Stack';
import {
  getVariantDefaultCost,
  getVariantPriceWithCurrency,
  isVariantCatalogPriceCommon,
  isVariantPricePerDay
} from '@Utils/activity/variantUtils';
import {IActivityProcessed} from '@Consts/globals';
import VariantsDescription from './activityPrices/VariantsDescription';
import CatalogPrice from './activityPrices/CatalogPrice';
import Price from './activityPrices/Price';
import PriceDescription from './activityPrices/PriceDescription';
import OfflinePrice from './activityPrices/OfflinePrice';
import {useFeatureFlag, Features} from '@Hooks/useFeatureFlag';

type Props = {
  activity: IActivityProcessed,
  activityHaveValidPrices: boolean
}

const ActivityPrice = ({activity, activityHaveValidPrices}: Props) => {
  const {
    variants,
    variantsDescription,
    passType
  } = activity;
  const lowestPriceVariant = getActivityLowestPriceVariant(variants);

  if (!activityHaveValidPrices) {
    return null;
  }

  if (lowestPriceVariant) {
    const withDifferentPrices = isActivityWithDifferentPrices(variants);
    const isPricePerDay = isVariantPricePerDay(lowestPriceVariant);
    const cost = getVariantDefaultCost(lowestPriceVariant)!;
    const {currency} = {...cost};
    const {type: variantType} = lowestPriceVariant;
    const {widgetType} = useSelector(getConfiguration);
    const {catalogPrice, catalogPriceDescription} = getCatalogPrice({
      cost, widgetType, variantsDescription
    });
    const {priceText, abbr} = getVariantPriceWithCurrency(
      {price: cost.price, passType, isPricePerDay, currency, variantType});
    const isVariantDifferentCatalogPrice = !isVariantCatalogPriceCommon(lowestPriceVariant);
    const {isEnabled} = useFeatureFlag(Features.TEST_B);

    return (
      <Stack sx={{mb: 1, width: {xs: 'auto', md: '200px'}, alignItems: 'end'}}>
        <Stack direction="row" sx={{alignItems: 'baseline', justifyContent: 'end'}}>
          {
            variantsDescription ?
              (
                <VariantsDescription variantsDescription={variantsDescription} />
              ) : (
                <>
                  {
                    cost && (
                      <>
                        {
                          catalogPrice && (
                            <CatalogPrice
                              catalogPrice={catalogPrice}
                              currency={currency}
                              withDifferentPrices={isVariantDifferentCatalogPrice}
                            />
                          )
                        }
                        <Price
                          withDifferentPrices={withDifferentPrices}
                          priceText={priceText}
                          abbr={abbr}
                        />
                      </>
                    )
                  }
                </>
              )
          }
        </Stack>
        <PriceDescription catalogPriceDescription={catalogPriceDescription} />
        {isEnabled && !variantsDescription ? <OfflinePrice variant={lowestPriceVariant} abbr={abbr} /> : null}
      </Stack>
    );
  }

  return null;
};

export default ActivityPrice;
