import {getLatesOrderUrl} from '@Features/order/orderUtils';
import {apiGet} from '@Utils/api/api';
import {TLatestOrderStorageData} from '@Consts/globals';
import {IOrder} from '@Consts/apiGlobals';
import {UseQueryOptions, useQuery} from '@tanstack/react-query';
import {useDispatch, useSelector} from 'react-redux';
import {getWidgetConfiguration} from '@Utils/configurationUtils';
import {useEffect, useState} from 'react';
import {onLatestOrderComplete} from '@Features/latestOrder/latestOrderActions';
import {getIsOrderComplete, getOrderPurchaseData} from '@Components/summary/utils';
import {getFacility} from '@Features/facility/facilitySelectors';
import facebookPixel from '@Utils/facebookPixel';
import gtm from '@Utils/gtm/core';
import {isOnlineOrder} from '@Utils/typeGuards';

export const REFETCH_TIMEOUT = 2000;

export const useFetchLatestOrder = (
  latestOrderStorageData: TLatestOrderStorageData,
  latestOrders: TLatestOrderStorageData[],
  queryOptions?: Pick<UseQueryOptions<IOrder>, 'refetchInterval'>
) => {
  const {
    code,
    orderId,
    widgetType,
    isPreviewMode,
    isEventsSent
  } = latestOrderStorageData;
  const dispatch = useDispatch();
  const widgetConfiguration = useSelector(getWidgetConfiguration);
  const facility = useSelector(getFacility)!;
  const {id: facilityId, timezone} = {...facility};
  const url = facilityId ? getLatesOrderUrl({widgetType, facilityId}, code, orderId) : null;
  const [isOrderComplete, setIsOrderComplete] = useState<boolean>(false);
  const {data, isInitialLoading, isError} = useQuery<IOrder>(
    {
      queryKey: [url],
      queryFn: () => apiGet(url, widgetConfiguration),
      refetchOnWindowFocus: false,
      refetchInterval: queryOptions?.refetchInterval,
      enabled: !isOrderComplete && !isPreviewMode
    }
  );

  const isPaymentComplete = data?.paymentState === 'completed' || data?.paymentState === 'skipped';

  useEffect(() => {
    if (data) {
      setIsOrderComplete(getIsOrderComplete(data));
    }
  }, [data]);

  useEffect(() => {
    if (!isEventsSent && isPaymentComplete && isOnlineOrder(data)) {
      dispatch(onLatestOrderComplete(
        latestOrderStorageData,
        latestOrders
      ));
      const {price, orderBuyer} = data;
      const {firstName, lastName, phoneNumber} = orderBuyer;

      facebookPixel.postPurchaseInfo(facility, price.amount, price.currency);

      if (orderBuyer && orderBuyer.email && facility) {
        gtm.addPurchase(
          facility,
          getOrderPurchaseData(data),
          {firstName, lastName, phoneNumber: phoneNumber ?? ''},
          widgetConfiguration.onlineGroupId!,
          timezone
        );
      }
    }
  }, [data]);

  return {
    latestOrder: data,
    isInitialLoading,
    isPaymentComplete,
    isLatestOrderFetchError: isError
  };
};
