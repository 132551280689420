import {ActiveView} from '@Components/checkout/types';
import {useEffect, useState} from 'react';
import {useAdmissionRealization} from './useAdmissionRealization';
import {useSelector} from 'react-redux';
import {getFacility, getFacilityTimezone} from '@Features/facility/facilitySelectors';
import {getAffiliationHash} from '@Features/configuration/configurationSelectors';
import pubsub from '@Utils/pubsub';
import {EVENT_BUY_ADMISSION_DATES_CHANGES_MONTH, EVENT_BUY_ADMISSION_DATES_SEES_DATES} from '@Utils/events';
import {DASH_DAY_FORMAT} from '@Utils/dayjs/const';
import {isActivityWithDateTimes} from '@Utils/activity/activity';
import {parseLanguage} from '@Utils/eventsUtils';
import locale from '@Utils/locale';
import {getTimezoneDate} from '@Utils/dayjs/dayjsUtils';
import {IDatesGroupedByDay} from '@Components/checkoutPages/admissionRealization/types';
import {DayOffersProcessed} from '@Features/activityAdmissionDates/types';
import {getAbTestType} from '@Features/storage/storageSelectors';

type props = {
  activeView: ActiveView,
  calendarMonth: number,
  dates: IDatesGroupedByDay[] | null,
  numberOfAvailableDays: number
  dayOffers: DayOffersProcessed[] | null
}

export const useAdmissionRealizationEvents = ({
  activeView,
  calendarMonth,
  dates,
  dayOffers,
  numberOfAvailableDays
}: props) => {
  const facility = useSelector(getFacility);
  const affiliationHash = useSelector(getAffiliationHash);
  const timezone = useSelector(getFacilityTimezone);
  const currentAbTestType = useSelector(getAbTestType);
  const [isInitialViewEventSend, setIsInitialViewEventSend] = useState(false);
  const [eventCalendarMonth, setEventCalendarMonth] = useState<number | null>(null);
  const {id: facilityId, name: facilityName, company} = {...facility};
  const {
    activityName,
    firstAdmissionDate,
    activityId,
    itemsUniqueVariantsIds,
    passType
  } = useAdmissionRealization(activeView);

  const sendAmplitudeEvent = (event: string, additionalData: object) => {
    pubsub.trigger(event, {
      facilityId,
      facilityName,
      industry: company?.industry,
      affiliationHash,
      activityId,
      activityName,
      chosenVariants: itemsUniqueVariantsIds,
      language: parseLanguage(locale.locale),
      ...additionalData
    });
  };

  useEffect(() => {
    if (eventCalendarMonth) {
      sendAmplitudeEvent(EVENT_BUY_ADMISSION_DATES_CHANGES_MONTH, {
        month: eventCalendarMonth + 1,
        numberOfAvailableDays
      });
    } else {
      setEventCalendarMonth(calendarMonth);
    }
  }, [calendarMonth]);

  useEffect(() => {
    if (!dates && !dayOffers) {
      return;
    }

    if (!isInitialViewEventSend && timezone) {
      setIsInitialViewEventSend(true);
      sendAmplitudeEvent(EVENT_BUY_ADMISSION_DATES_SEES_DATES, {
        firstAdmissionDate: getTimezoneDate(firstAdmissionDate, timezone).format(DASH_DAY_FORMAT),
        userNeedToChooseHour: isActivityWithDateTimes(passType),
        numberOfAvailableDays,
        abTest: currentAbTestType
      });
    }
  }, [dates, dayOffers]);

  return {
    sendAmplitudeEvent
  };
};
