import Typography from '@mui/material/Typography';
import locale from '@Utils/locale';
import React from 'react';

type Props = {
    withDifferentPrices: boolean,
    priceText: string,
    abbr: string | null,
    isVariantPrice?: boolean
}

const Price = ({withDifferentPrices, priceText, abbr, isVariantPrice}: Props) => (
  <>
    {withDifferentPrices && (
      <Typography sx={{fontSize: '16px'}}>
        {locale.translate('from').toLowerCase()}&nbsp;
      </Typography>
    )}
    <Typography
      variant={isVariantPrice ? 'h6' : 'h5'}
      sx={{
        fontSize: isVariantPrice ? '20px' : '24px',
        fontWeight: 700,
        textAlign: 'right',
        whiteSpace: 'nowrap'
      }}
    >
      {priceText}
      {
        abbr &&
        <Typography component="span" sx={{fontSize: '16px'}}>
            &nbsp;/ {abbr}
        </Typography>
      }
    </Typography>
  </>
);

export default Price;
