import {IOnlineOrder, IOrder} from '@Consts/apiGlobals';

export const getIsOrderComplete = (order?: IOrder) => {
  if (!order) {
    return false;
  }
  const {isDocumentAddressAvailable, documentAddress} = order;

  const documentAddressIsComplete = isDocumentAddressAvailable ? !!documentAddress : true;
  const paymentStateIsComplete = order.paymentState === 'completed' || order.paymentState === 'skipped';

  return documentAddressIsComplete && paymentStateIsComplete;
};

export const getOrderPurchaseData = (order: IOnlineOrder) => {
  const {orderId, completedAt, tickets: orderTickets, tax, price, orderShippingPrice} = order;
  const {amount: orderPrice, currency} = price;
  const tickets = orderTickets.map(ticket => {
    const {
      ticketId, activityId, activityVariantName, activityName,
      price, discountCode, admissionDate, ticketFormattedName, activityVariantId, isAdmissionDateWithStartHour
    } = ticket;

    return {
      ticketId,
      activityId,
      activityVariantName,
      activityName,
      price: price.amount,
      discountCode,
      admissionDate,
      ticketFormattedName,
      activityVariantId,
      isAdmissionDateWithStartHour
    };
  });

  return {
    orderId,
    tax,
    currency,
    completedAt,
    email: order.orderBuyer.email,
    tickets,
    price: orderPrice,
    shipping: orderShippingPrice
  };
};
