import {OnlinePaymentMethods} from '@Components/checkout/types';
import {EWidgetType} from '@Consts/globals';
import {InvoiceAddressCountry} from '@Consts/invoiceCountries';
import {EnabledPaymentMethods} from '@Hooks/usePaymentMethodsEnabled';

export type GetUserIdProps = {
  widgetType: EWidgetType
  companyUserId?: string | number, //number in business client / string in internal client
  orderBuyerHash?: string | null,
  currentUserId?: string | null
}

export type EventProperties = {
  userId?: string;
  facilityId?: string;
  facilityName?: string;
  websiteUrl?: string;
  results?: string;
  language?: string;
  widgetLanguage?: string;
  industry?: string;
  affiliationHash?: string;
  onlineGroupId?: string;
  invoiceDataSubmitted?: string;
  numberOfTickets?: string;
  formValues?: string;
  requestBody?: string;
  orderCode?: string;
  orderId?: string;
  orderState?: string;
  orderPaymentUrl?: string;
  error?: string;
  formData?: string;
  activityId?: string;
  activityName?: string;
  errorTitle?: string;
  errorType?: string;
  errorStatus?: string;
  fetchingTime?: string;
  datesSince?: string;
  datesUtil?: string;
  passType?: string;
  priorityFromTopToBottom?: string;
  originalLanguage?: string;
  result?: string;
  source?: string;
  saleType?: string;
  message?: string;
  additions?: string;
  questionText?: string;
  questionType?: string;
  required?: string;
  items?: string;
  shippingMethods?: string;
  answerText?: string;
  groupId?: string;
  url?: string;
  destinationUrl?: string;
  description?: string;
  validationError?: string;
  responseData?: string;
  basketItems?: string;
  abTest?: string;
  tag?: string;
  revenueTotal?: number;
  revenueFromUpsell?: number;
  currency?: string;
  chosenVariants?: string;
  missingVariants?: string;
  selectedShippingMethodId?: string;
  isAgreeMarketingConsents?: string;
  isMarketingConsentsEnabled?: boolean;
  marketingConsentsTitle?: string;
  marketingConsentsDescription?: string;
  isFirstLoading?: boolean;
  firstAdmissionDate?: string;
  admissionDateDay?: string;
  admissionDateDescription?: string;
  admissionDateHour?: string;
  userNeedToChooseHour?: string;
  numberOfAvailableDays?: number;
  numberOfAvailableHours?: number;
  numberOfSelectedDates?: number;
  month?: number;
  widgetMode?: string;
  paymentMethod?: OnlinePaymentMethods;
  enabledPaymentMethods?: EnabledPaymentMethods;
  referrer?: string;
  uuid?: string;
  configuration?: string;
  disabled?: string;
  bookingWindow?: string;
  country?: InvoiceAddressCountry;
  nip?: string;
  addToBasketMethod?: AddToBasketMethod;
}

export type UserProperties = {
  userId: string;
  companyUserId: string;
  companyID: string;
  userType: string;
  companyName: string;
  userName: string;
  language: string;
}

export enum AddToBasketMethod {
  BUTTON = 'button',
  INPUT = 'input'
}
