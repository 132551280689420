import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import PropTypes from 'prop-types';
import ParticipantsForm from '../checkoutPages/participantsForm/ParticipantsForm';
import {getCheckoutItemsDescription} from '@Utils/checkout';
import AdmissionRealization from '@Components/checkoutPages/admissionRealization/AdmissionRealization';
import HotelNarrowedDate from '@Components/checkoutPages/hotelNarrowedDate/HotelNarrowedDate';
import QuestionForm from '@Components/questionForm/QuestionForm';
import Additions from '@Components/additions/Additions';
import {ECheckoutViewsTypes} from '@Utils/checkoutViews/checkoutViewsConsts';
import ShippingMethods from '@Components/shippingMethods/shippingMethods';
import ParticipantsGroup from '@Components/checkoutPages/participantsGroup/ParticipantsGroup';
import Upsell from '@Components/upsell/Upsell';

const CheckoutPage = ({
  activeView,
  onChangeView,
  fullCheckoutProgress,
  onPageClose,
  requestedCheckoutPageId,
  checkoutPagesRef
}) => {
  const {type, items} = activeView;
  const selectedItemsDescription = items ? getCheckoutItemsDescription(items) : null;

  return (
    <div ref={checkoutPagesRef}>
      {
        (type === ECheckoutViewsTypes.UPSELL) && (
          <Upsell
            onChangeView={onChangeView}
            activeView={activeView}
            requestedCheckoutPageId={requestedCheckoutPageId}
          />
        )
      }
      {
        (type === ECheckoutViewsTypes.PARTICIPANTS) && (
          <ParticipantsForm
            activeView={activeView}
            description={selectedItemsDescription}
            onChangeView={onChangeView}
            fullCheckoutProgress={fullCheckoutProgress}
            onPageClose={onPageClose}
            requestedCheckoutPageId={requestedCheckoutPageId}
          />
        )
      }

      {
        (
          type === ECheckoutViewsTypes.DATES ||
          type === ECheckoutViewsTypes.TIMES
        ) && (
          <AdmissionRealization
            activeView={activeView}
            description={selectedItemsDescription}
            onChangeView={onChangeView}
            fullCheckoutProgress={fullCheckoutProgress}
            onPageClose={onPageClose}
            requestedCheckoutPageId={requestedCheckoutPageId}
          />
        )
      }

      {
        type === ECheckoutViewsTypes.HOTEL_NARROWED && (
          <HotelNarrowedDate
            activeView={activeView}
            description={selectedItemsDescription}
            onChangeView={onChangeView}
            fullCheckoutProgress={fullCheckoutProgress}
            onPageClose={onPageClose}
            requestedCheckoutPageId={requestedCheckoutPageId}
          />
        )
      }

      {
        type === ECheckoutViewsTypes.QUESTIONS &&
          <QuestionForm
            activeView={activeView}
            description={selectedItemsDescription}
            onChangeView={onChangeView}
            fullCheckoutProgress={fullCheckoutProgress}
            onPageClose={onPageClose}
            requestedCheckoutPageId={requestedCheckoutPageId}
          />
      }

      {
        type === ECheckoutViewsTypes.ADDITIONS &&
          <Additions
            activeView={activeView}
            description={selectedItemsDescription}
            onChangeView={onChangeView}
            fullCheckoutProgress={fullCheckoutProgress}
            onPageClose={onPageClose}
            requestedCheckoutPageId={requestedCheckoutPageId}
          />
      }

      {
        type === ECheckoutViewsTypes.PARTICIPANTS_GROUP &&
          <ParticipantsGroup
            activeView={activeView}
            description={selectedItemsDescription}
            onChangeView={onChangeView}
            fullCheckoutProgress={fullCheckoutProgress}
            onPageClose={onPageClose}
            requestedCheckoutPageId={requestedCheckoutPageId}
          />
      }

      {
        type === ECheckoutViewsTypes.SHIPPING_METHODS &&
          <ShippingMethods
            activeView={activeView}
            description={selectedItemsDescription}
            onChangeView={onChangeView}
            fullCheckoutProgress={fullCheckoutProgress}
            onPageClose={onPageClose}
            requestedCheckoutPageId={requestedCheckoutPageId}
          />
      }
    </div>
  );
};

CheckoutPage.propTypes = {
  activeView: PropTypes.object,
  onChangeView: PropTypes.func.isRequired
};

export default CheckoutPage;
