import {ECurrency, EPassType, ICost} from '@Consts/apiGlobals';
import {VariantType} from '@Features/activityAdmissionDates/types';
import {getFormattedPrice} from '@Utils/format';
import locale from '@Utils/locale';
import {isVariantGroup} from '@Utils/variantType';
import {isActivityMultipleHotelNarrowed, isActivitySingleGroupEvent} from './activity';

type GetVariantDefaultCostProps = {
    cost: ICost | null,
    datesCostMin: ICost | null
}

export const getVariantDefaultCost = ({cost, datesCostMin}: GetVariantDefaultCostProps) => {
  if (datesCostMin) {
    return datesCostMin;
  }

  if (cost) {
    return cost;
  }

  return null;
};

type IsVariantPricePerDayProps = {
    isPricePerAdmissionDate: boolean,
    numberOfAdmissionDatesMin: number,
    numberOfAdmissionDatesMax: number
}

export const isVariantPricePerDay = ({
  isPricePerAdmissionDate,
  numberOfAdmissionDatesMin,
  numberOfAdmissionDatesMax
}: IsVariantPricePerDayProps) =>
  isPricePerAdmissionDate &&
    (numberOfAdmissionDatesMin > 1 || numberOfAdmissionDatesMin !== numberOfAdmissionDatesMax);

type GetVariantPriceWithCurrencyProps = {
    price: number,
    passType?: EPassType,
    isPricePerDay?: boolean,
    currency: ECurrency,
    variantType?: VariantType
}

export const getVariantPriceWithCurrency = (
  {
    price,
    passType,
    isPricePerDay,
    currency,
    variantType
  }: GetVariantPriceWithCurrencyProps): {priceText: string, abbr: string | null} => {
  const currencyText = currency && locale.translate(`${currency}`);
  const priceWithCurrency = `${getFormattedPrice(price)} ${currencyText}`;

  if ((passType && isActivitySingleGroupEvent(passType)) || isVariantGroup(variantType)) {
    return {priceText: priceWithCurrency, abbr: locale.translate('perPersonAbbr')};
  }

  if (passType && isActivityMultipleHotelNarrowed(passType)) {
    return {priceText: priceWithCurrency, abbr: locale.translate('hotelNight')};
  }

  if (isPricePerDay) {
    return {priceText: priceWithCurrency, abbr: locale.translate('day')};
  }

  return {priceText: priceWithCurrency, abbr: null};
};

type IsVariantCatalogPriceCommon = {
  datesCostMin: ICost | null,
  datesCostMax: ICost | null,
}

export const isVariantCatalogPriceCommon = ({datesCostMin, datesCostMax}: IsVariantCatalogPriceCommon) =>
  datesCostMin?.catalogPrice === datesCostMax?.catalogPrice;

