import React, {useEffect, useRef} from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import CheckoutPage from './CheckoutPage';
import CheckoutHeader from './CheckoutHeader';
import {useScroll} from '@Utils/hooks/useScroll';

const CheckoutPagesListItem = ({
  checkoutView,
  activeView,
  onHeaderClick,
  onChangeView,
  fullCheckoutProgress,
  onPageClose,
  requestedCheckoutPageId
}) => {
  const checkoutPagesRef = useRef(checkoutPagesRef);
  const isHeaderActive = activeView?.id === checkoutView?.id;
  const {scrollToElement} = useScroll();

  function scrollEffect() {
    if (checkoutPagesRef.current) {
      scrollToElement(checkoutPagesRef.current);
    }
  }

  useEffect(() => {
    setTimeout(() => scrollEffect(), 400);
  }, [activeView?.id]);

  return (
    <>
      {
        isHeaderActive && activeView ?
          <div>
            <CheckoutPage
              className="dl-checkout-pages"
              activeView={activeView}
              onChangeView={onChangeView}
              fullCheckoutProgress={fullCheckoutProgress}
              onPageClose={onPageClose}
              requestedCheckoutPageId={requestedCheckoutPageId}
              checkoutPagesRef={checkoutPagesRef}
            />
          </div> :
          <CheckoutHeader
            checkoutView={checkoutView}
            onClick={onHeaderClick}
            activeView={activeView}
          />
      }
    </>
  );
};

export default CheckoutPagesListItem;
