import Stack from '@mui/material/Stack';
import React, {useEffect} from 'react';
import PaymentLoader from './PaymentLoader';
import PaymentComplete from './PaymentComplete';
import {useSelector} from 'react-redux';
import {getFacility} from '@Features/facility/facilitySelectors';
import {getAffiliationHash} from '@Features/configuration/configurationSelectors';
import {EVENT_SHOW_SUCCESS_SCREEN} from '@Utils/events';
import pubsub from '@Utils/pubsub';
import {parseLanguage} from '@Utils/eventsUtils';
import locale from '@Utils/locale';
import {useSummaryData} from '../../hooks/useSummaryData';
import {getAbTestType} from '@Features/storage/storageSelectors';

const PaymentDetails = () => {
  const {isPaymentComplete, latestOrderStorageData, isPreviewMode} = useSummaryData();
  const facility = useSelector(getFacility);
  const affiliationHash = useSelector(getAffiliationHash);
  const currentAbTestType = useSelector(getAbTestType);

  useEffect(() => {
    const {
      isEventsSent
    } = latestOrderStorageData;
    const {id: facilityId, name: facilityName, company} = {...facility};
    const {industry} = {...company};

    pubsub.trigger(EVENT_SHOW_SUCCESS_SCREEN, {
      facilityId,
      facilityName,
      industry,
      affiliationHash,
      language: parseLanguage(locale.locale),
      isFirstLoading: !isEventsSent,
      abTest: currentAbTestType
    });
  }, []);

  return (
    <>
      <Stack sx={{justifyContent: 'center', alignItems: 'center', my: 3}} >
        {
          isPaymentComplete || isPreviewMode ? <PaymentComplete /> : <PaymentLoader />
        }
      </Stack>
    </>
  );
};

export default PaymentDetails;
