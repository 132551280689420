import pubsub from './pubsub';
import {EVENT_POST_MESSAGE_NOT_SUPPORTED} from './events';
import {VerticalAlignment} from './hooks/useScroll';

export type DirectFrameMessageParams =
  | {name: 'scrollCenter'}
  | {name: 'iframeRequestScript'}
  | {name: 'iframeInitialize'}
  | {name: 'setHeightDl', height: number}
  | {name: 'widgetCloseRequest'}
  | {name: 'payment', href: string}
  | {name: 'triggerScroll'}
  | {name: 'scrollRef', iframeElementYCord: number, verticalAlignment: VerticalAlignment}
  | {name: 'scrollTop', offset: number}
  | {name: 'scrollCenter'}
  | {name: 'gtmAnalytics', gtmData: unknown}
  | {name: 'setStorage', key: string, data: unknown, namespace: string}
  | {name: 'removeStorage', key: string, namespace: string}
  | {name: 'facebookPixel', action: string, value: unknown, currency: unknown}
  | {name: 'addParam', paramName: string, value: string}
  | {name: 'deleteParam', paramName: string}
  | {name: 'isApplePayAvailable'}

type SharedOptionalParams = {isModal?: boolean, url?: string};

function directFrameMessage(params: DirectFrameMessageParams & SharedOptionalParams) {
  if (window?.parent?.postMessage && typeof window.parent.postMessage === 'function') {
    return window.parent.postMessage(params, '*');
  }

  return pubsub.trigger(EVENT_POST_MESSAGE_NOT_SUPPORTED, {url: document.referrer});
}

export default directFrameMessage;
