import React from 'react';
import {IActivityVariantProcessed} from '@Consts/globals';
import Typography from '@mui/material/Typography';
import locale from '@Utils/locale';
import {green} from '@mui/material/colors';
import {getFormattedPrice} from '@Utils/format';

type Props = {
    variant: IActivityVariantProcessed;
    abbr: string | null
}

const OfflinePrice = ({variant, abbr}: Props) => {
  const offlinePrice = variant?.minPricing?.offlinePrice || null;

  if (!offlinePrice) {
    return null;
  }

  const {isHigherPriceAvailable, amount: price, currency} = offlinePrice;
  const currencyText = currency && locale.translate(`${currency}`);
  const formattedPrice = getFormattedPrice(price);

  return (
    <>
      <Typography
        sx={{
          textAlign: 'right',
          my: 0.5,
          // Border is part of styles for green label.
          borderRight: `2px solid ${green[50]}`,
          borderRadius: '4px'
        }}
      >
        <Typography
          variant="body2"
          component="span"
          sx={labelStyle}
        >
          {locale.translate('onlineInAdvanceCheaper')}
        </Typography>
      </Typography>
      <Typography variant="body2" sx={{fontSize: '14px', textAlign: 'right'}}>
        {locale.translate('atTicketOffice')}&nbsp;
        {isHigherPriceAvailable && `${locale.translate('from').toLowerCase()} `}
        {formattedPrice} {currencyText} {!!abbr && ` / ${abbr}`}
      </Typography>
    </>
  );
};

export default OfflinePrice;

// Styles for green label.
// The label should be as wide as its text content
// That is why we add a pseudo element
// Solution for text longer than 1 line
const labelStyle = {
  fontSize: '14px',
  position: 'relative',
  zIndex: 1,
  p: '2px',
  pr: 0,
  '&::before': {
    content: '""',
    position: 'absolute',
    backgroundColor: green[50],
    height: '100%',
    width: '100%',
    top: 0,
    left: 0,
    zIndex: -1,
    borderRadius: '4px 0 0 4px'
  }
};
