import {useContext} from 'react';
import {SummaryContext} from '@Contexts/SummaryDataProvider';
import {ISummaryData, ISummaryDataProvider} from '@Contexts/types';
import {getBasketValue} from '@Utils/basket';
import {getPreviewTickets} from '@Components/summary/tickets/utils';

export const useSummaryData = () => {
  const summaryData = useContext(SummaryContext) as ISummaryDataProvider;
  const isPreviewMode = summaryData.isPreviewMode;

  let paymentState = summaryData?.onlineOrder?.paymentState ??
    summaryData?.businessOrder?.paymentState ??
    summaryData?.internalOrder?.paymentState;

  // this orderId is the same as in latestOrderStorageData (useLatestOrderStorageData / useFetchLatestOrder)
  const orderId = summaryData?.onlineOrder?.orderId ??
    summaryData?.businessOrder?.orderId ??
    summaryData?.internalOrder?.orderId ??
    null;

  if (isPreviewMode) {
    const {latestOrderStorageData, isWigetOnlineType} = summaryData;
    const {basket} = latestOrderStorageData;
    const basketPriceValue = basket ? getBasketValue(basket, true) : null;

    paymentState = basketPriceValue && isWigetOnlineType ? 'completed' : 'skipped';
  }

  const basket = summaryData.latestOrderStorageData?.basket;
  const previewTickets = basket && isPreviewMode ? getPreviewTickets(basket) : null;
  const appleWalletPassUrl = summaryData?.onlineOrder?.appleWalletPassUrl;
  const googleWalletPassUrl = summaryData?.onlineOrder?.googleWalletPassUrl;

  return {
    ...summaryData,
    isPaymentComplete: !isPreviewMode ? summaryData.isPaymentComplete : true,
    previewOrderEmail: summaryData.latestOrderStorageData?.email,
    paymentState,
    orderId,
    previewTickets,
    appleWalletPassUrl,
    googleWalletPassUrl
  } as ISummaryData;
};
