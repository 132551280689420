import {IFacility, IOrderSavedData} from '@Consts/apiGlobals';
import directFrameMessage from '@Utils/directFrameMessage';
import {EGtmEvents, GTM_MESSAGE_NAME} from './core';
import {TPurchaseOrderData, TPurchaseOrderTickets} from './types';
import {getFormatAdmissionDate} from './utils';

interface ProductsKeys {
  idKey?: string,
  nameKey?: string,
  categoryKey?: string,
  priceKey?: string,
  quantityKey?: string
}

export const getItemsMutable = (order: TPurchaseOrderData, config: ProductsKeys) => {
  const {tickets} = order;
  const {
    idKey,
    nameKey,
    categoryKey,
    priceKey,
    quantityKey
  } = config;

  return tickets.map(({ticketId, activityName, price, ticketFormattedName}) => ({
    ...idKey && {[idKey]: ticketId},
    ...nameKey && {[nameKey]: ticketFormattedName},
    ...categoryKey && {[categoryKey]: activityName},
    ...priceKey && {[priceKey]: price},
    ...quantityKey && {[quantityKey]: 1}
  }));
};

const getOrderData = (order: TPurchaseOrderData, savedData: IOrderSavedData) => {
  const {firstName, lastName, phoneNumber} = savedData;
  const {orderId, email, completedAt} = order;

  return {
    attributes: {
      order_number: orderId,
      date: completedAt
    },
    customer: {
      billing: {
        first_name: firstName,
        last_name: lastName,
        email,
        phone: phoneNumber
      }
    },
    items: getItemsMutable(order, {
      idKey: 'id',
      nameKey: 'name',
      priceKey: 'price',
      quantityKey: 'quantity'
    })
  };
};

const getBaseData = (order: TPurchaseOrderData) => {
  const {orderId, tax, price} = order;

  return {
    transactionId: orderId,
    transactionAffiliation: 'Droplabs',
    transactionTotal: price,
    transactionTax: tax,
    transactionProducts: getItemsMutable(order, {
      idKey: 'sku',
      nameKey: 'name',
      categoryKey: 'category',
      priceKey: 'price',
      quantityKey: 'quantity'
    })
  };
};

type ViewItemsCompletedData = {
  [ticketId: string]: {
    item_id: number;
    item_name: string;
    item_list_id: number | null;
    item_variant: string | null;
    quantity: number;
    price: number,
    admission_date: string | null;
    coupon: string | null
  };
};

const getEcommerceItemsData = (
  tickets: TPurchaseOrderTickets[], onlineGroupId: number, timezone: string
) => Object.values(
  tickets.reduce<ViewItemsCompletedData>((acc, item) => {
    const {activityVariantId, activityId, activityName,
      activityVariantName, price, isAdmissionDateWithStartHour, admissionDate, discountCode} = item;

    acc[activityVariantId] = {
      item_id: activityId,
      item_name: activityName,
      item_list_id: onlineGroupId,
      item_variant: activityVariantName,
      quantity: (acc[activityVariantId]?.quantity ?? 0) + 1,
      price,
      admission_date: getFormatAdmissionDate(isAdmissionDateWithStartHour, admissionDate, timezone),
      coupon: discountCode
    };

    return acc;
  }, {})
);

export const getEcommerceData = (order: TPurchaseOrderData, onlineGroupId: number, timezone: string) => {
  const {orderId, price, tax, currency, shipping} = order;

  return {
    transaction_id: orderId,
    affiliation: 'Droplabs',
    value: price,
    tax,
    currency,
    shipping,
    items: getEcommerceItemsData(order.tickets, onlineGroupId, timezone)
  };
};

const getGtmData = ({order, savedData, onlineGroupId, timezone}: {
  order: TPurchaseOrderData,
  savedData: IOrderSavedData,
  onlineGroupId: number,
  timezone: string
}) => ({
  event: EGtmEvents.GTM_PURCHASE,
  orderData: getOrderData(order, savedData),
  ...getBaseData(order),
  ecommerce: getEcommerceData(order, onlineGroupId, timezone)
});

const addPurchase = (
  facility: IFacility, order: TPurchaseOrderData,
  savedData: IOrderSavedData, onlineGroupId: number, timezone: string) => {
  const {isGoogleTagManagerEnabled} = facility;

  if (!isGoogleTagManagerEnabled) {
    return;
  }

  directFrameMessage({
    name: GTM_MESSAGE_NAME,
    gtmData: getGtmData({order, savedData, onlineGroupId, timezone})
  });
};

export default {
  addPurchase
};
