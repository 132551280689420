import React, {useEffect, useState} from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import {useSelector} from 'react-redux';
import {getConfiguration, getIndicatedActivityId} from '@Features/configuration/configurationSelectors';
import locale from '@Utils/locale';
import Text from '@Components/common/text/Text';
import ActivityItem from './activity/ActivityItem';
import PropTypes from 'prop-types';
import './activities.scss';
import ActivitiesList from './ActivitiesList';
import Basket from '@Components/basket/Basket';
import {getBasketItems} from '@Features/basket/basketSelectors';
import {ETextAlign, ETextSize} from '@Components/common/text/types';
import FilterTags from '@Components/filterTags/FilterTags';
import {
  getActivitiesTags,
  getActivitiesByPriority,
  getFilteredActivitiesByTag
} from './activitiesListUtils';
import FlexBox from '@Components/common/flexBox/FlexBox';
import {EFlexDirection, EJustifyContent} from '@Components/common/flexBox/types';
import {getFacility} from '@Features/facility/facilitySelectors';
import {parseLanguage} from '@Utils/eventsUtils';
import pubsub from '@Utils/pubsub';
import {EVENT_VIEW_ACTIVITIES} from '@Utils/events';
import {useLocation, useNavigate} from 'react-router-dom';
import {useScroll} from '@Utils/hooks/useScroll';
import {WidgetMode} from '../../widget/types';
import Button from '@mui/material/Button';
import {getActivityToScrollInto, getIsVisibleByDefault} from './activitiesContentUtils';
import gtm from '@Utils/gtm/core';
import {usePaymentMethodsEnabled} from '@Hooks/usePaymentMethodsEnabled';
import {getAbTestType} from '@Features/storage/storageSelectors';

const ActivitiesContent = ({activities}) => {
  const indicatedActivityId = useSelector(getIndicatedActivityId);
  const {facilityId, affiliationHash, onlineGroupId, isModal, parentUrl} = useSelector(getConfiguration);
  const restActivities = [...activities];
  const numberOfActivities = activities.length;
  const indicatedActivity = getIndicatedActivity();
  const [listIsVisible, setListVisibility] = useState(false);
  const [activeTag, setActiveTag] = useState(null);
  const basketItems = useSelector(getBasketItems);
  const indicatedActivityListIndex = indicatedActivityId ? 1 : 0;
  const facility = useSelector(getFacility);
  const {name: facilityName, company} = facility;
  const {industry} = company;
  const activitiesByPriority = getActivitiesByPriority(restActivities);
  const facilityTags = getActivitiesTags(activitiesByPriority);
  const {state: locationState} = useLocation();
  const navigate = useNavigate();
  const {scrollToElement} = useScroll({enableForModal: true});
  const isVisibleByDefault = getIsVisibleByDefault(basketItems, indicatedActivityId);
  const enabledPaymentMethods = usePaymentMethodsEnabled({checkBasketPrice: false});
  const currentAbTestType = useSelector(getAbTestType);

  useEffect(() => {
    if (isVisibleByDefault) {
      setListVisibility(true);
    }
  }, [isVisibleByDefault]);

  function initializeActivitiesEffect() {
    pubsub.trigger(EVENT_VIEW_ACTIVITIES, {
      facilityId,
      facilityName,
      numberOfActivities,
      language: parseLanguage(locale.locale),
      industry,
      affiliationHash,
      onlineGroupId,
      activityId: indicatedActivityId,
      url: parentUrl,
      widgetMode: isModal ? WidgetMode.modal : WidgetMode.inline,
      enabledPaymentMethods,
      abTest: currentAbTestType
    });
  }
  useEffect(() => {
    initializeActivitiesEffect();
    if (locationState?.scrollToActivities) {
      const id = setTimeout(() => {
        // Old counter minus button. Shown always with value greater than 0.
        const minusButton = document.querySelector('[data-dl-scroll]');
        const elementToBeShown = minusButton ?? getActivityToScrollInto('dl-activities-list');

        if (elementToBeShown) {
          scrollToElement(elementToBeShown);
          navigate('', {}); // clear location state to avoid scrolling again
        }
      }, 200);

      return () => clearTimeout(id);
    }
  }, []);

  function handleListToggle() {
    return setListVisibility(!listIsVisible);
  }

  function getIndicatedActivity() {
    if (indicatedActivityId) {
      const indicatedActivityIndex = activities.findIndex(({id}) => id === indicatedActivityId);

      if (indicatedActivityIndex >= 0) {
        return restActivities.splice(indicatedActivityIndex, 1)[0];
      }

      return null;
    }

    return null;
  }

  useEffect(() => {
    if (numberOfActivities) {
      gtm.viewItemList(facility, onlineGroupId);
    }
  }, []);

  return (
    <>
      {
        !!numberOfActivities &&
        !indicatedActivityId &&
        facilityTags &&
          <FilterTags
            setActiveTag={setActiveTag}
            activeTag={activeTag}
            facilityTags={facilityTags}
          />
      }

      <FlexBox
        className="dl-activities"
        justify={EJustifyContent.CENTER}
        direction={EFlexDirection.COLUMN}
      >
        {
          numberOfActivities ? (
            <>
              {
                indicatedActivity && (
                  <div>
                    <ActivityItem
                      activity={indicatedActivity}
                      orderNumber={indicatedActivityListIndex}
                      indicated
                      numberOfActivities={numberOfActivities}
                    />
                    {
                      numberOfActivities > 1 && (
                        <Button
                          onClick={handleListToggle}
                          sx={activitiesExpandButtonTheme}
                        >
                          {locale.translate(listIsVisible ? 'hideOtherActivities' : 'seeOtherActivities')}
                        </Button>
                      )
                    }
                  </div>
                )
              }

              <div id="dl-activities-list" role="listbox">
                {
                  (!indicatedActivity || listIsVisible) &&
                  <ActivitiesList
                    activities={getFilteredActivitiesByTag(activitiesByPriority, activeTag)}
                    indicatedActivityListIndex={indicatedActivityListIndex}
                    numberOfActivities={numberOfActivities}
                  />
                }
              </div>

              {
                basketItems?.length ? <Basket isModal={isModal} /> : null
              }
            </>
          ) : (
            <Text
              size={ETextSize.MEDIUM}
              align={ETextAlign.CENTER}
            >
              {locale.translate('noActivitiesInSales')}
            </Text>
          )
        }
      </FlexBox>
    </>
  );
};

ActivitiesContent.propTypes = {
  activities: PropTypes.array.isRequired
};

const activitiesExpandButtonTheme = theme => ({
  color: theme.palette.text.primary,
  fontWeight: 400,
  textTransform: 'none',
  textDecoration: 'underline',
  ':hover': {
    textDecoration: 'underline'
  },
  mb: 3
});

export default ActivitiesContent;
