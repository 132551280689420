import gtmPurchase from './purchase';
import gtmAddOrRemove from './cartActions';
import gtmListAction from './activityListAction';
import gtmAdmissionDatesAction from './admissionDatesAction';
import gtmCheckoutActions from './checkoutActions';

export const GTM_MESSAGE_NAME = 'gtmAnalytics';
export enum EGtmEvents {
  GTM_PURCHASE = 'purchase',
  GTM_ADD_TO_CART = 'addToCart',
  GTM_REMOVE_FROM_CART = 'removeFromCart',
  GTM_TAG_VIEW_ITEM_LIST = 'view_item_list',
  GTM_TAG_ADD_TO_CART = 'add_to_cart',
  GTM_TAG_REMOVE_FROM_CART = 'remove_from_cart',
  GTM_TAG_VIEW_ADMISSION_DATES = 'view_admission_dates',
  GTM_TAG_VIEW_ADMISSION_COMPLETED = 'view_admissions_completed',
  GTM_TAG_BEGIN_PAYMENT = 'begin_payment',
  GTM_TAG_BEGIN_CHECKOUT = 'begin_checkout'
}

export default {
  addPurchase: gtmPurchase.addPurchase,
  addToCart: gtmAddOrRemove.addToCart,
  removeFromCart: gtmAddOrRemove.removeFromCart,
  viewItemList: gtmListAction.viewItemList,
  viewAdmissionDates: gtmAdmissionDatesAction.viewAdmissionDates,
  viewAdmissionsCompleted: gtmAdmissionDatesAction.viewAdmissionsCompleted,
  beginPayment: gtmCheckoutActions.beginPayment,
  beginCheckout: gtmCheckoutActions.beginCheckout
};
