declare global {
  interface Window {
    ApplePaySession?: {
    canMakePayments(): boolean;
  };
}
}

export const getIsApplePaySupported = () => {
  try {
    if (window.ApplePaySession) {
      return window.ApplePaySession.canMakePayments();
    }
    return false;
  } catch {
    return false;
  }
};
