import {getAbTestType} from '@Features/storage/storageSelectors';
import {useSelector} from 'react-redux';
import {ABTest} from '@Contexts/abTestProvider/types';

export enum Features {
  TEST_B = 'test_b',
}

export const useFeatureFlag = (feature: Features) => {
  const currentAbTestType = useSelector(getAbTestType);

  if (feature === Features.TEST_B && currentAbTestType === ABTest.TEST_B) {
    return {isEnabled: true};
  }

  return {isEnabled: false};
};
